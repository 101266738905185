<div id="unsupported" *ngIf="unsupported">
  <div class="h2" style="margin-bottom: 30px;">Your browser is old and cannot play videos.<br>Please follow these instructions to download Firefox.</div>

  <article class="wiki-doc">
    <h2 class="title">How to download and install Firefox on Windows</h2>
    <div class="separator"></div>
    <section id="doc-content" class="">

      <p>This article explains how to download and install Firefox on Windows using a simplified online installer.
      </p>
      <ol><li> Visit <a href="https://www.mozilla.org/firefox/new/?utm_medium=referral&amp;utm_source=support.mozilla.org" target="_blank"><strong>this Firefox download page</strong></a> in any browser, such as Microsoft Internet Explorer<span class="for" data-for="win10" style="display: none;"> or Microsoft Edge</span>.
      </li><li> Click the <span class="button">Download Now</span> button. The Firefox Installer that downloads will automatically offer you the best available version of Firefox for your computer.
        <dl><dt>
          <img alt="Download-Firefox-Win10" class="wiki-image frameless" data-original-src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-21-58-28-5616a2.png" src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-21-58-28-5616a2.png" title="">
        </dt></dl>
        <ul><li>If you use Microsoft Internet Explorer<span class="for" data-for="win10" style="display: none;"> or Microsoft Edge</span>, a notification bar will appear at the bottom of the page with the options to run the installer or save the file to your computer. Click <span class="button">Run</span> to start the process.
        </li><li>In other browsers, you may need to first save the Firefox installer to your computer, then open the file you downloaded.<br><strong>Note:</strong> If you see an <em>Open File -  Security Warning</em> dialog, click <span class="button">Open</span> or <span class="button">Run</span>.
        </li></ul>
        <dl><dt>
          <img alt="Firefox-60-open-file-warning-win10" class="wiki-image frameless" data-original-src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-22-02-04-45d74b.png" src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-22-02-04-45d74b.png" title="">
          <br>
        </dt></dl>
      </li><li> The <em>User Account Control</em> dialog may open, to ask you to allow the Firefox Installer to make changes to your computer. If this dialog appears, click <span class="button">Yes</span> to start the installation.
        <dl><dt>
          <img alt="Firefox-Installer-win10-UAC" class="wiki-image frameless" data-original-src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-22-04-34-a54b02.png" src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-22-04-34-a54b02.png" title="">
        </dt></dl>
      </li><li> Wait for Firefox to finish installing.
        <dl><dt>
          <img alt="Firefox-60-Installer" class="wiki-image frameless" data-original-src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-22-00-14-385d8a.png" src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-22-00-14-385d8a.png" title="">
          <div class="note"><strong>Note</strong>: The Firefox Installer may contain an Update or Re-install button and an option to restore default settings and remove add-ons, if a very outdated Firefox version or very old profile data is detected. Clear the checkbox to keep the old data and click <span class="button">Update</span> or <span class="button">Re-install</span> to start the installation. <br>
            <img alt="FirefoxInstaller-Reinstall" class="wiki-image frameless" data-original-src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-04-18-03-33-11-0bc4a1.png" src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-04-18-03-33-11-0bc4a1.png" title="">
          </div>
        </dt></dl>
      </li><li> When the installation is complete, Firefox will open.
      </li></ol>
      <div class="note"><strong>Congratulations, you are done installing Firefox!</strong> Double-click on the Mozilla Firefox icon whenever you want to go online.
        <dl><dt>
          <img alt="Firefox-Icons-Win10" class="wiki-image frameless" data-original-src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-22-00-50-f2f720.png" src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2018-06-10-22-00-50-f2f720.png" title="">
        </dt></dl></div>
    </section>
  </article>

  <div class="h3">After you have installed Firefox, click the link in your email or text to start again.</div>
</div>
<div style="display: flex; flex-direction: column;" >
  <div *ngIf="currentStep && currentStep.type == 'video'" class="task-display-area">
    <vg-player (onPlayerReady)="onPlayerReady($event)" vg-responsive="true" >
      <vg-overlay-play>
      </vg-overlay-play>
      <div class="text-overlay-top" *ngIf="paused">
          <div>YOU'RE WATCHING</div>
          <div>{{task.name.toUpperCase()}}</div>
          <div>{{task.provider_name ? "BY " + task.provider_name.toUpperCase() : ""}}</div>
      </div>

      <div class="text-overlay-bottom" *ngIf="paused">
          TAP TO PLAY
      </div>

      <div class="video-header" *ngIf="paused"></div>
      <div class="video-header-2" *ngIf="paused"></div>
      <vg-buffering></vg-buffering>
      <vg-controls #vcontrols [vgAutohide]="true" >
        <div style="display: flex">
        <vg-play-pause></vg-play-pause>


        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
          <div style="line-height: 50px; color: white">/</div>
        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

        <vg-track-selector></vg-track-selector>

        <vg-volume style="max-width: 50px;"></vg-volume>

        <vg-fullscreen></vg-fullscreen>
        </div>
        <div class="up-next-area" >
          <div class="task-item-selector">
            <mat-select appearance="fill" class="form-control task-selector" id="video_task_selector" [(value)]="currentIndex"
                        (selectionChange)="changeStep(this.currentIndex)">
              <mat-option *ngFor="let s of steps" [value]="s.step_number - 1">{{s.name}}</mat-option>
            </mat-select>
          </div>
        </div>
      </vg-controls>


      <video disablePictureInPicture #media [vgMedia]="media" [vgHls]="currentVideo.src" id="videoframe"
             preload="auto" crossorigin [poster]="currentVideo.thumbnail"
      >
        <track #cueTrack vgCuePoints (onExitCuePoint)="onExitCue($event)">
      </video>
    </vg-player>
  </div>
  <div *ngIf="currentStep && currentStep.type == 'image'" class="task-display-area">
    <img class="image-slide" src="currentStep.img_url" />
    <div class="up-next-area-form" >
      <div class="up-next-title-form">
        <div *ngIf="upNext">Up Next: {{upNext.name}}</div>
      </div>
      <div class="task-item-selector-form">
        <mat-select appearance="fill" class="form-control task-selector" id="image_task_selector" [(value)]="currentIndex"
                    (selectionChange)="changeStep(this.currentIndex)">
          <mat-option *ngFor="let s of steps" [value]="s.step_number - 1">{{s.name}}</mat-option>
        </mat-select>
      </div>
    </div>
    <button class="previous-step" mat-icon-button color="primary" (click)="previousStep()" *ngIf="currentIndex > 0">
      <mat-icon>previous</mat-icon>
      Previous Step
    </button>
    <button class="next-step" mat-icon-button color="primary" (click)="advanceFromImage()">
      <mat-icon>next</mat-icon>
      Next Step
    </button>
  </div>
  <div *ngIf="currentStep && currentStep.type == 'form'" class="task-display-area">
    <app-form-step #formStep [fields]="currentStep.form_json"></app-form-step>
    <div class="up-next-area-form" >
      <div class="up-next-title-form">
        <div *ngIf="upNext">Up Next: {{upNext.name}}</div>
      </div>
      <div class="task-item-selector-form">
        <mat-select appearance="fill" class="form-control task-selector" id="form_task_selector" [(value)]="currentIndex"
                    (selectionChange)="changeStep(this.currentIndex)">
          <mat-option *ngFor="let s of steps" [value]="s.step_number - 1">{{s.name}}</mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div class="rest-period" *ngIf="showRest">
    <div style="display: inline-block; width: 49%">
      <circle-progress
        [backgroundStrokeWidth] = 0
        [backgroundPadding] = 7
        [radius]= "101"
        [space]= "-20"
        [toFixed]= "0"
        [maxPercent]= "100"
        [outerStrokeWidth]= "20"
        [outerStrokeColor]= "'#00b630'"
        [outerStrokeLinecap]= "'square'"
        [innerStrokeColor]= "'#e7e8ea'"
        [innerStrokeWidth]= "20"
        [title]= "restTitle"
        [titleFontSize]= "12"
        [subtitleFontSize]= "20"
        [animation]= false
        [animateTitle]= false
        [animationDuration]= 1000
        [showSubtitle]= false
        [showUnits]= false
      ></circle-progress>
    </div>
    <div style="display: inline-block; width: 50%">
      <div>Step Complete.  Take a rest.</div>
    </div>

  </div>
  <div class="finished" style="text-align: center" *ngIf="finished && !task.is_survey_task">
    <div class="good-job h1" style="margin-top: 5%;">
      You're Done! Thank You!
    </div>
    <div style="margin-top: 3em;" class="h4">
      <button mat-raised-button color="primary" style="font-size: 1em;" (click)="watchAgain()" title="Click to watch again from the beginning.">Watch Again</button>
    </div>
    <div style="margin-top: 3em;" class="h3">
      If you want to watch this again later, click the button in the email, or tap the link texted to you.
    </div>
  </div>

  <div class="finished" style="text-align: center" *ngIf="finished && task.is_survey_task">
    <div class="good-job h1" style="margin-top: 5%;">
      <img src="assets/icons/happy.svg" style="width: 30vw; height: 30vh" />
      <div>Thank you for answering our survey.</div>
    </div>

    <div style="margin-top: 6em;" class="h3">
      You can now close this page.
    </div>
  </div>


  <div class="finished" *ngIf="finished && mobile && false">

    <div class="good-job h1">
      You're Done!  Thank You!
    </div>
    <div style="margin-top: 20px;" class="h4">
      <button mat-raised-button color="primary" style="font-size: 1em;" (click)="watchAgain()" title="Click to watch again from the beginning.">Watch Again</button>
    </div>
    <div style="margin-top: 40px;" class="h4">
      If you want to watch this again later, click the button in the email, or tap the link texted to you.
    </div>
    <div style="margin-top: 20px" class="h4" *ngIf="inviteLink">
      Get the app for more advanced features.  Click the button below.
    </div>
    <div style="margin-top: 20px" class="h4" *ngIf="inviteLink">
      <button mat-raised-button color="primary" style="font-size: 1em;" (click)="getApp()" title="Click to go to the app store and download.">Get The App</button>
    </div>
  </div>
</div>
