import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';


export interface ItemEvent {
  event: string,
  data: string
}

@Injectable({
  providedIn: 'root'
})
export class ListItemEventService {
  constructor() { }
  private events = new Subject<ItemEvent>();

  sendEvent(e: ItemEvent) {
    this.events.next(e);
  }

  subscribeToEvents(): Observable<ItemEvent> {
    return this.events.asObservable();
  }
}
