import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormlyFormOptions, FormlyFieldConfig, FormlyForm} from '@ngx-formly/core';
import { Renderer2, RendererFactory2 } from '@angular/core';

@Component({
  selector: 'app-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./form-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormStepComponent implements AfterViewInit, OnChanges, AfterViewChecked {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  @Input()
  fields: FormlyFieldConfig[] = [];

  @Input()
  options: FormlyFormOptions = {};

  @Input()
  type: 'normal' | 'right' = 'normal'
  // for displaying results
  @Input()
  formValues: any = {};

  // should be true when displaying results.
  @Input()
  readOnly: boolean = false;

  @Input()
  editing: boolean = false;

  @Input()
  isEditor: boolean = false;

  @Output() recordEvent: EventEmitter<any> = new EventEmitter();

  @Output() fieldAdded: EventEmitter<any> = new EventEmitter();
  @Output() fieldUpdated: EventEmitter<any> = new EventEmitter();
  @Output() fieldRemoved: EventEmitter<any> = new EventEmitter();
  formGroup: FormGroup;

  @ViewChild('scrollableArea') scrollableArea: ElementRef;
  @ViewChild('formlyForm') formlyForm : FormlyForm;

  public canScrollDown: boolean = false;
  public canScrollUp: boolean = false;
  private lastScrollTop: number = 0;
  private needsScrollCheck: boolean = false;

  scrollUp() {
    const scrollableAreaElement = this.scrollableArea.nativeElement;
    scrollableAreaElement.scrollBy({ top: -100, behavior: 'smooth' });
    this.checkScroll();
  }

  scrollDown() {
    const scrollableAreaElement = this.scrollableArea.nativeElement;
    scrollableAreaElement.scrollBy({ top: 100, behavior: 'smooth' });
    this.checkScroll();// adjust 100 to the distance you want to scroll.
  }

  checkScroll() {
    const element = this.scrollableArea.nativeElement;
    const scrollTop = Math.ceil(element.scrollTop);
    this.canScrollDown = element.scrollHeight > element.clientHeight && scrollTop < (element.scrollHeight - element.clientHeight);
    this.canScrollUp = element.scrollTop > 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.fields) {
      this.formGroup = new FormGroup<any>({});
      this.setRequiredFields();
      this.needsScrollCheck = true;
    }
  }

  ngAfterViewChecked() {
    if (this.needsScrollCheck) {
      setTimeout(() => {
        this.scrollableArea.nativeElement.scrollTop = 0;
        this.needsScrollCheck = false;
        this.checkScroll();
      }, 125);
    }
  }

  ngAfterViewInit() {
    if(this.readOnly) {
      // go through each item and set props.disable and/or props.readonly to true?
      if(typeof(this.fields) == 'string')
        this.fields = JSON.parse(this.fields);

      this.formGroup.disable();
      this.options.resetModel();
    } else if (!this.readOnly && !this.editing) {
      this.setRequiredFields();
    }
    this.checkScroll();
  }

  setRequiredFields() {
    let id = 0;
    for(let f of this.fields) {
      if(["checkbox", "radio", "select"].includes(<string> f.type)) {
        f.props.required = true;
        f.props.attributes = {id: "formly-input-id-" + (++id).toString()}
      }
    }
  }

  findFirstInvalidControl(fields: FormlyFieldConfig[]): FormlyFieldConfig | undefined {
    for (const field of fields) {
      if (field.fieldGroup) {
        // If this is a field group, recursively check its fields.
        const invalidInGroup = this.findFirstInvalidControl(field.fieldGroup);
        if (invalidInGroup) {
          return invalidInGroup;
        }
      }

      // If this is a form control, check if it's invalid.
      if (field.formControl && field.formControl.invalid && field.props.required == true) {
        return field;
      }
    }
    return undefined;
  }

  record() {
    if(this.formGroup.valid)
      this.recordEvent.emit(this.formValues);
    else {
      const firstInvalidControl = this.findFirstInvalidControl(this.fields);

      if (firstInvalidControl) {
        const invalidControlEl: HTMLElement = document.querySelector(`#${firstInvalidControl.id}`);
        if (invalidControlEl) {
          let parent = <HTMLElement>invalidControlEl.closest('mat-form-field');
          this.renderer.addClass(parent, 'invalid-highlight');
          invalidControlEl.scrollIntoView({ behavior: 'smooth' });
          setTimeout(() => {
            this.renderer.removeClass(parent, 'invalid-highlight');
          }, 1000);
        }
      }
    }
  }

}
