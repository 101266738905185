import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UserService } from '@app/services/user.service';
import { PalUser } from '@core/models/pal-user';
import { Subscription } from 'rxjs';
import { PermissionManagementComponent } from '../permission-management/permission-management.component';
import {PageService} from '@services/page.service';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("permissionsComponent", {read: PermissionManagementComponent})
  public permissionsManagement: PermissionManagementComponent;
  public _user: PalUser;
  permissions : [];
  public changedEvent: EventEmitter<any> = new EventEmitter<any>();
  subs: Subscription[] = [];
  @Input() set user(value: PalUser) {
    this._user = value;
    this.setForm();
  }
  _show_trainer: boolean = false;
  @Input() set show_trainer(value: boolean) {
    this._show_trainer = value;
  }

  @Input()
  public options?: Partial<{ inputPassword: boolean }> = null;

  @Input()
  public showTaskArea: boolean = false;
  get user(): PalUser {
    return this._user;
  }

  private initalizing: boolean = true;
  userForm: UntypedFormGroup;

  constructor(private cdr: ChangeDetectorRef, private pageService: PageService,private userService: UserService) {
    this.initalizing = true;
  }

  ngOnInit(): void {
    this.setForm();
    if(this._user.is_trainer)
      this.retrieveAndSetPermissions();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  emailOrPhone: ValidatorFn = (control): ValidationErrors | null => {
    if (!this.userForm)
      return null;

    const email = this.userForm.controls.email;
    const phone = this.userForm.controls.phone_number;

    return email.value || phone.value ? null : { noContactEntered: true };
  };

  passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!this.userForm)
      return null;

      let newPwField = this.userForm.get("new_password");
      let confirmPwField = this.userForm.get("password_confirmation");
      if(!newPwField || !confirmPwField)
        return null;

      let newPw = newPwField.value;
      let confirmPw = confirmPwField.value;

      if((newPw === "" && confirmPw === "") || newPw === confirmPw) {
        newPwField.setErrors(null);
        confirmPwField.setErrors(null);
        return null;
      }

      newPwField.setErrors({ passwordMismatch: true });
      confirmPwField.setErrors({ passwordMismatch: true });
      return {passwordMismatch: true};
  }

  ngAfterViewInit() {
    if (!this.userForm.controls.is_trainer.value) this.initalizing = false;
    const targetElem = document.getElementById("firstName");
    targetElem.focus();
  }

  retrieveAndSetPermissions(){
    this.subs.push(
      this.userService.getAvailablePermissionsGroups().subscribe((permissions) => {
        this.permissions = permissions;
        this.subs.push(
          this.permissionsManagement.permissionsChanged.subscribe((_permissions) => {
            if (this.initalizing) return;
            this.changedEvent.emit('');
            this.pageService.showSaveButton();
          })
        );
        this.initalizing = false;
      })
    );
  }

  contactChanged() {
    this.userForm.controls.email.updateValueAndValidity();
    this.userForm.controls.phone_number.updateValueAndValidity();
    this.cdr.detectChanges();
    if (this.userForm.controls.email.value)
      this.userForm.controls.comm_email.setValue(true);
    if (this.userForm.controls.phone_number.value)
      this.userForm.controls.comm_text.setValue(true);
  }

  setForm(): void {
    if (!this._user)
      this._user = {
        id: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        display_name: '',
        phone_number: '',
        email: '',
        comm_email: false,
        comm_text: false,
        is_trainer: false,
        new_password: '',
        password_confirmation: '',
        permissions: [{
          name: '',
          id: '',
        }],
      };

    this._user.new_password = '';
    this._user.password_confirmation = '';
    this.userForm = new UntypedFormGroup(
      {
        first_name: new UntypedFormControl(this._user.first_name, [
          Validators.required,
        ]),
        middle_name: new UntypedFormControl(this._user.middle_name),
        last_name: new UntypedFormControl(this._user.last_name, [
          Validators.required,
        ]),
        display_name: new UntypedFormControl(this._user.display_name),
        email: new UntypedFormControl(this._user.email, [this.emailOrPhone]),
        phone_number: new UntypedFormControl(this._user.phone_number, [
          this.emailOrPhone,
        ]),
        comm_email: new UntypedFormControl(this._user.comm_email),
        comm_text: new UntypedFormControl(this._user.comm_text),
        is_trainer: new UntypedFormControl(this._user.is_trainer),
        new_password: new UntypedFormControl(this._user.new_password),
        password_confirmation: new UntypedFormControl(this._user.password_confirmation),
        permissions: new FormArray([]),
      },
      { validators: this.passwordMatchValidator }
    );


    this.subs.push(
      this.userForm.valueChanges.subscribe((valueChanged) => {
        if(!this.initalizing) {
          this.changedEvent.emit('');
          this.pageService.showSaveButton();
        }
      })
    );
  }

  validateAndReturn(): PalUser {
    Object.values(this.userForm.controls).forEach(control => {
      control.markAsTouched();
    });
    this.userForm.updateValueAndValidity();
    this.cdr.detectChanges();
    if (this.userForm.status === 'INVALID')
      return null;
    if(this.userForm.get('is_trainer').value)
      this.userForm.value.permissions =  this.permissionsManagement.returnPermissions();
    else
      this.userForm.value.permissions = null;

    return { id: this._user.id, ...this.userForm.value };
  }


}
