import {inject, NgModule} from '@angular/core';
import {Routes, RouterModule, ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ContentLayoutComponent} from '@core/layouts/content-layout/content-layout.component';
import {AuthGuard} from '@core/guards/auth.guard';
import {UnsavedInformationGuard} from '@core/guards/unsaved-information.guard';
import {PalOrganization} from '@core/models/pal-organization';
import {UserService} from '@services/user.service';
import {of, tap} from 'rxjs';
import {AdminLayoutComponent} from '@core/layouts/admin-layout/admin-layout.component';

export const allOrgs: ResolveFn<PalOrganization> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(UserService).getOrganizations().pipe(tap(result => {
      return of(result);
    }));
  };

const routes: Routes = [

    {
      path: 'profile',
      component: ContentLayoutComponent,
      canActivate: [AuthGuard],
      canDeactivate: [UnsavedInformationGuard],
      resolve: {orgs: allOrgs},
      loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
    },
  {
    path: 'organization',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedInformationGuard],
    resolve: {orgs: allOrgs},
    children: [
      {
        path: '',
        loadChildren: () => import('@modules/organization/organization.module').then(m => m.OrganizationModule),

      }]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedInformationGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('@modules/admin/admin.module').then(m => m.AdminModule),
      }]
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('@modules/landing/landing.module').then(m => m.LandingModule)
      }],
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: true })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
