import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], excludedItems: any[]): any[] {
    return items.filter(item =>
      !excludedItems.some(excludedItem => excludedItem.id === item.id)
    );
  }
}
