import { Component, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'formly-field-header',
  template: `<div *ngIf="!props.draggable" class="h3" (click)="props.click($event)" [title]="props.title">{{props.label}}</div>
             <div *ngIf="props.draggable" cdkDrag class="h3" (click)="props.click($event)" [title]="props.title">{{props.label}}</div>`,
})
export class HeaderFieldType extends FieldType<FieldTypeConfig> {
}

@Component({
  selector: 'formly-field-text',
  template: `<div *ngIf="!props.draggable" (click)="props.click($event)" [title]="props.title">{{props.label}}</div>
             <div *ngIf="props.draggable" (click)="props.click($event)" cdkDrag [title]="props.title">{{props.label}}</div>`,
})
export class TextFieldType extends FieldType<FieldTypeConfig> {
}

@Component({
  selector: 'formly-field-separator',
  template: `<div *ngIf="!props.draggable" (click)="props.click($event)" style="padding: 5px;" [title]="props.title" ><hr /></div>
             <div *ngIf="props.draggable" cdkDrag (click)="props.click($event)" style="padding: 5px;" [title]="props.title" ><hr /></div>`,
})
export class SeparatorFieldType extends FieldType<FieldTypeConfig> {
}

@Component({
  selector: 'formly-field-label',
  template: `<div *ngIf="!props.draggable" class="h5" (click)="props.click($event)" [title]="props.title">{{props.label}}</div>
  <div *ngIf="props.draggable" cdkDrag class="h5" (click)="props.click($event)" [title]="props.title">{{props.label}}</div>`,
})
export class LabelFieldType extends FieldType<FieldTypeConfig> {
}


