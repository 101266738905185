<div *ngIf="!data.showSecondaries" mat-dialog-content class="modal-body text-center message-dialog">
  <p>{{data.prompt}}</p>
  <div mat-dialog-actions>
    <button type="button" class="confirm-yes-button" mat-raised-button color="primary" (click)="confirm()" >Ok</button>
  </div>
  <button type="button" class="confirm-yes-button" mat-raised-button color="primary" (click)="cancel()" >Cancel</button>
</div>
<div *ngIf="data.showSecondaries" mat-dialog-content class="modal-body text-center message-dialog">
  <div class="h5" style="margin-botton: 24px">{{data.prompt}}</div>
  <app-secondary-contacts [(secondaryContacts)]="secondary" [item]="'task'"></app-secondary-contacts>
  <div mat-dialog-actions style="display: flex; justify-content: center; gap: 10px">
    <button type="button" class="confirm-yes-button" mat-raised-button color="primary" (click)="send(secondary.length > 0)" >Send</button>
    <button type="button" class="confirm-yes-button" mat-raised-button color="primary" (click)="cancel()" >Cancel</button>
  </div>
</div>
