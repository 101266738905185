<h5 mat-dialog-title>Protocol Task Properties</h5>
<div mat-dialog-content style="display: flex; flex-direction: column; min-height: 130px">
    <mat-label>Due Date Type:</mat-label>
    <mat-radio-group *ngIf="!data.pre_post" [formControl]="dueDateType" style="display: flex; flex-direction: column; gap: 8px; margin-top: 16px">
      <mat-radio-button [value]="0" title="A task notification will be sent the protocol day, and it will be due that day."> Day In Protocol</mat-radio-button>
      <mat-radio-button [value]="1" title="Due date will be the procedure date.  A new task notification will be sent the day in the protocol."> Procedure Date</mat-radio-button>
    </mat-radio-group>
    <mat-radio-group *ngIf="data.pre_post" [formControl]="dueDateType" style="display: flex; flex-direction: column; gap: 8px; margin-top: 16px">
      <mat-radio-button [value]="0" title="A task notification will be sent the protocol day, and it will be due that day."> Day In Protocol</mat-radio-button>
      <mat-radio-button [value]="1" title="A new task notification will be sent the day in the protocol. The due date will be the number of days in the future"> Future Date</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="data.pre_post && dueDateType.value === 1">
      <mat-label>Days Until Due:</mat-label>
      <mat-form-field>
        <input matInput [formControl]="dueDateOffset" type="number" min="0" step="1" style="margin-left: 8px;">
      </mat-form-field>
    </div>
</div>
<mat-dialog-actions style="display: flex; flex-direction: row; justify-content: space-between">
  <button mat-raised-button color="default" (click)="closeDialog()"><mat-icon>cancel</mat-icon> Cancel</button>
  <button mat-raised-button color="accent" (click)="updateItem()"><mat-icon>update</mat-icon> Update</button>
</mat-dialog-actions>
