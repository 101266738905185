import {Component, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserInformationComponent} from '@shared/component/user-information/user-information.component';
import {BehaviorSubject} from 'rxjs';

class CreateMemberDialogData {
  patientMemberTitle: string; // Member or Patient
  organizationId?: number;
}

@Component({
  selector: 'app-add-member-dialog',
  templateUrl: './add-member-dialog.component.html',
})
export class AddMemberDialogComponent {
  patientMemberTitle: any;
  @ViewChild('mainUser') mainUser: UserInformationComponent;
  @Output() returnVal: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private dialogRef: MatDialogRef<AddMemberDialogComponent, CreateMemberDialogData>, @Inject(MAT_DIALOG_DATA) public data: CreateMemberDialogData) {
    dialogRef.disableClose ??= true;
    this.patientMemberTitle = data.patientMemberTitle || "Member";
  }

  add() {
    let u = this.mainUser.validateAndReturn();
    if(!u)
      return;

    this.returnVal.next(u);

  }
}
