import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@modules/landing/pages/login/login.component';
import {SingleTaskComponent} from '@modules/landing/pages/single-task/single-task.component';
import {OnboardComponent} from '@modules/landing/pages/onboard/onboard.component';
import {PrivacyComponent} from '@modules/landing/pages/privacy/privacy.component';
import {RequestPasswordResetComponent} from '@modules/landing/pages/request-password-reset/request-password-reset.component';
import {NewPasswordComponent} from '@modules/landing/pages/new-password/new-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'task/:uuid',
        component: SingleTaskComponent
      },
      {
        path: 'link/:uuid',
        component: SingleTaskComponent
      },
      {
        path: 'branch',
        component: OnboardComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'reset/:token/:uid',
        component: NewPasswordComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingRoutingModule { }
