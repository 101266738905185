import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {liveSearch} from '@shared/component/livesearch/livesearch';
import {UserService} from '@services/user.service';
import {PalLibraryItem} from '@core/models/pal-library-item';

import {PageService} from '@services/page.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalData} from '@app/services/modal.service';

export type FindAssetLibraryDialogData = Omit<ModalData, 'onResponse' | 'prompt' | 'pre'> & {
  searchOnly: string,
}

@Component({
  selector: 'app-find-asset-for-library',
  templateUrl: './find-asset-for-library.component.html',
  styleUrls: ['./find-asset-for-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindAssetForLibraryComponent implements OnInit, OnDestroy {
  private dialog: boolean = undefined;
  @Input() searchOnly: string = null;
  currentItem: PalLibraryItem;
  private searchSubject = new BehaviorSubject<string>('');
  libraryItems: Observable<PalLibraryItem[]>;
  orgId: number;
  @Output() selectedItem: EventEmitter<PalLibraryItem> = new EventEmitter<PalLibraryItem>();
  private subs: Subscription[] = [];

  constructor(private dialogRef: MatDialogRef<FindAssetForLibraryComponent, FindAssetLibraryDialogData>,
              private userService: UserService, private pageService: PageService, @Inject(MAT_DIALOG_DATA) public data: FindAssetLibraryDialogData) {
    dialogRef.disableClose ??= true;
    this.searchOnly = this.data.searchOnly;
    if (this.data.onClose) {
      dialogRef.afterClosed().subscribe(() => this.data.onClose({dialog: this.dialog}));
    }
  }

  ngOnInit(): void {
    this.libraryItems = this.searchSubject.pipe(
      liveSearch(query => this.userService.searchForLibraryItems(15, query, [], this.searchOnly))
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  buttonClick(evt) {
    let id = parseInt(evt.data.split('_')[1]);
    this.previewVideo(id);
  }

  searchItems(needle: string) {
    this.searchSubject.next(needle);
  }

  selectItem(item: PalLibraryItem) {
    this.currentItem = item;
  }

  confirmItem() {
    this.dialog = true;
    if (this.currentItem) {
      this.selectedItem.next(this.currentItem);
      this.dialogRef.close();
    }
  }

  previewForm(id: number) {
    this.pageService.previewForm(id, this.orgId);
  }

  previewImage(id: number) {
    this.pageService.previewImage(id, this.orgId);
  }

  previewVideo(id: number) {
    this.pageService.previewVideo(id, this.orgId);
  }

  hideModal() {
    this.dialog = false;
    this.dialogRef.close();
  }
}
