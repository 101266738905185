import {AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {PalTaskItem} from '@core/models/pal-task-item';
import {VgApiService} from '@videogular/ngx-videogular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '@services/api.service';
import {PalTask} from '@core/models/pal-task';
import {DeviceDetectorService} from 'ngx-device-detector';
import {FormStepComponent} from '@shared/component/form-step/form-step.component';
import {PageService} from '@services/page.service';
import {Media} from '@app/shared/component/Dialog';

@Component({
  selector: 'app-single-task',
  templateUrl: './single-task.component.html',
  styleUrls: ['./single-task.component.scss']
})
export class SingleTaskComponent implements OnInit,AfterViewInit {

  @ViewChildren("formStep")
  formsList: QueryList<FormStepComponent>;

  formStep: FormStepComponent;

  taskUuid: string;
  taskId: number;
  task: PalTask;
  steps: PalTaskItem[];
  currentStep: PalTaskItem;
  upNext: PalTaskItem;
  currentIndex: number;
  autoplay: boolean = false;
  paused: boolean = true;
  showRest: boolean = false;
  finished: boolean = false;
  mobile: boolean = false;
  totalSteps: number;
  media: any[];
  repsComplete: number;
  vapi: VgApiService;
  restTitle: string;
  taskFeedback: string;
  rating: number = 0;
  unsupported: Boolean = false;
  currentVideo: Media = {title: '', src: '', thumbnail: ''};
  inviteLink: string;
  recordFormEvent: Subscription;
  progress: {step_number: number, last_time: number} = {step_number: 0, last_time: 0};
  videoDuration: number;
  tickTimer: any;
  seekTime: number;
  subs: Subscription[] = [];
  otpCompleteMode: boolean = false;

  constructor(private titleService: PageService, private apiService: ApiService, private ar: ActivatedRoute,
              private deviceService: DeviceDetectorService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.otpCompleteMode = this.ar.snapshot.queryParamMap.get('otp') === 'true';
    this.ar.params.subscribe(p => {
      this.ar.queryParams.subscribe(q => {
        this.taskUuid = p.uuid;
        this.mobile = this.deviceService.isMobile() || this.deviceService.isTablet();
        this.apiService.getTaskByUUID(this.taskUuid, { condensed: Boolean(q.condensed) }).subscribe(t => {
          this.task = t.task;
          this.inviteLink = t.invite_link;
          this.taskId = this.task.id;
          this.steps = t.steps;
          this.totalSteps = t.steps.length;
          this.currentStep = t.steps[0];
          this.upNext = t.steps[1];
          if(t.progress?.step_number)
            this.selectStep(t.progress.step_number, t.progress.last_time);
          else
            this.selectStep(0, 0);
        })
        this.currentIndex = 0;
        this.repsComplete = 0;
      });
    })
  }

  ngAfterViewInit() {
   this.subs.push(this.formsList.changes.subscribe((comps: QueryList <FormStepComponent>) => {
      if(this.recordFormEvent)
        this.recordFormEvent.unsubscribe();
      this.formStep = comps.first;
      if(this.formStep)
        this.recordFormEvent = this.formStep.recordEvent.subscribe(value => {
          this.submitForm(value);
        })
    }));
  }

  async ngOnDestroy() {
    await screen.orientation.unlock();
    await this.titleService.unFullScreen();
    for(const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  onPlayerReady(api: VgApiService) {
    this.vapi = api;

    this.subs.push(this.vapi.getDefaultMedia().subscriptions.ended.subscribe(() => {
      this.paused = false;
      this.videoEnd();
      clearInterval(this.tickTimer);
    }));

    this.subs.push(this.vapi.getDefaultMedia().subscriptions.error.subscribe((err) => {
      this.videoEnd();
      clearInterval(this.tickTimer);
    }));

    this.subs.push(this.vapi.getDefaultMedia().subscriptions.loadedMetadata.subscribe(() => {
      this.videoDuration = this.vapi.getDefaultMedia().duration;
      this.vapi.seekTime(this.seekTime);
      if(!this.paused)
        this.vapi.play();
    }));

    this.subs.push(this.vapi.getDefaultMedia().subscriptions.play.subscribe(async () => {
      this.paused = false;
      this.showRest = false;
      this.finished = false;
      if(this.mobile) {
        await this.titleService.goFullScreen();
        await screen.orientation.lock('landscape');
      }
      this.markStepStarted(this.steps[this.currentIndex].id, this.videoDuration);
      this.tickTimer = setInterval(() => {
        if(this.vapi.getDefaultMedia().state == 'playing') {
          this.updateStepProgress(this.vapi.currentTime);
          if(Math.abs(this.vapi.currentTime - this.vapi.duration) <= 25)
            this.markStepCompleted(this.steps[this.currentIndex].id);
        }
      }, 5000);
    }));

    this.subs.push(this.vapi.getDefaultMedia().subscriptions.pause.subscribe(() => {
      this.paused = !this.vapi.getDefaultMedia().isCompleted;
      clearInterval(this.tickTimer);
    }));
  }

  getApp() : void {
    window.open(this.inviteLink, "_blank");
  }

  markStepStarted(step_id, total_time = 0) : void {
    this.apiService.singleMarkStepStarted(this.taskUuid, step_id, total_time).subscribe(() => {});
  }

  updateStepProgress(currentTime) : void {
    this.apiService.singleUpdateStepProgress(this.taskUuid, this.currentStep.id, currentTime).subscribe(() => {});
  }

  markStepCompleted(step_id) : void {
    this.apiService.singleMarkStepCompleted(this.taskUuid, step_id, this.otpCompleteMode).subscribe(() => {});
  }

  onExitCue(evt) : void {
    if(this.repsComplete < this.currentStep.num_reps)
    {
      // TODO: if a cue point, go back to start
      this.vapi.seekTime(this.currentStep.rep_start_time);
      this.repsComplete++;
    }
  }

  videoEnd() : void {
    if(++this.repsComplete < this.currentStep.num_reps)
    {
      this.vapi.seekTime(this.currentStep.rep_start_time || 0);
      this.vapi.play();
    } else if(this.currentStep.rest > 0) {
      this.showRest = true;
      // TODO: start rest timer
    } else {
      this.markStepCompleted(this.steps[this.currentIndex].id);
      this.nextStep();
      if(!this.finished) {
        this.vapi.play();
      }
    }
  }

  starStep(numstars : number) : void {
    this.apiService.singleStarStep(this.taskUuid, this.currentIndex, numstars);
  }

  starTask(numstars : number ) : void {
    this.apiService.singleStarTask(this.taskUuid, numstars);
  }

  sendFeedback() : void {
    this.apiService.singleTaskFeedback(this.taskUuid, this.taskFeedback);
  }

  submitForm(data) : void {
    this.apiService.singleFormFeedback(this.taskUuid, this.currentStep.id, this.currentStep.form_data, data, this.otpCompleteMode).subscribe(() => {
      if (this.task.is_survey_task) this.formStep.formValues = {};
    });
    this.nextStep();
  }

  advanceFromImage() : void {
    this.markStepCompleted(this.steps[this.currentIndex].id);
    this.nextStep();
  }

  nextStep() : void {
    if(this.currentIndex < this.totalSteps - 1)
    {
      this.selectStep(this.currentIndex + 1);
    } else {
      this.currentStep = null;
      this.autoplay = false;
      this.showRest = false;
      this.finished = true;
    }
  }

  previousStep() : void {
    if(this.currentIndex > 0)
    {
      this.selectStep(this.currentIndex - 1);
    }
  }

  changeStep(num: number) : void {

    this.selectStep(num);
  }

  selectStep(num : number, time: number = 0) : void {
    if(num < 0 || num > this.totalSteps)
      return;

    clearInterval(this.tickTimer);

    this.seekTime = 0;
    this.currentStep = this.steps[num];
    this.currentIndex = num;
    this.repsComplete = 0;
    this.videoDuration = 0;

    if(this.recordFormEvent && this.currentStep.type != 'form')
      this.recordFormEvent.unsubscribe();

    if(this.currentStep.type == 'video' || this.currentStep.type == '') {
      this.currentVideo.src = this.currentStep.sources[0].url;
      this.currentVideo.title = this.currentStep.name;
      this.currentVideo.thumbnail = this.currentStep.thumbnail_url;
      this.seekTime = time;
    } else if(this.currentStep.type == 'form') {
      this.currentStep.form_json = JSON.parse(this.currentStep.form_data);
      this.changeDetector.detectChanges();
    }
    this.upNext = this.steps[num+1];
    this.currentIndex = num;
    if(this.autoplay && !this.paused && this.currentStep.type == 'video')
      this.vapi.play();
    else if (this.currentStep.type !== 'video')
      this.markStepStarted(this.steps[this.currentIndex].id, 0);
  }

  watchAgain() : void {
    this.autoplay = true;
    this.finished = false;
    this.selectStep(0);
  }
}
