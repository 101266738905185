import { Inject, Injectable } from '@angular/core';
import { Subject, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { MatSnackBar} from '@angular/material/snack-bar';
import { ApiService } from '@services/api.service';
import { PreviewStepsDialogComponent, PreviewStepsModalData } from '@app/shared/component/Dialog/preview-steps-dialog/preview-steps-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { VideoPreviewDialogComponent, VideoPreviewModalData, FormPreviewDialogComponent, FormPreviewModalData } from '@app/shared/component/Dialog';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private _title: string = '';
  subject = new Subject();
  showCancel = new Subject();
  showSave = new Subject();
  showDelete = new Subject();
  saveEvent = new Subject();
  deleteEvent = new Subject();
  hideButtons = new Subject();
  showSpinnerOverlay = new Subject();
  hideSpinnerOverlay = new Subject();
  public extraActions: { id: string; text: string; icon?: string; callback: () => any; }[] = [];
  errorSnackbar: any = null;

  constructor(@Inject(DOCUMENT) private document: any, private snackBar : MatSnackBar,
              private apiService: ApiService, private dialog: MatDialog) {}

  changeHeaderTitle(newTitle: string) {
    this._title = newTitle;
    let _t = this;
    setTimeout(function() {
      _t.subject.next(_t._title);
    }, 0)

  }

  clear() {
    this._title = '';
    let _t = this;
    setTimeout(function() {
      _t.subject.next(_t._title);
    }, 0);
  }

  showCancelButton() {
    this.showCancel.next('');
  }

  showSaveButton() {
    this.showSave.next('');
  }

  showDeleteButton() {
    this.showDelete.next('');
  }
  hideAllButtons() {
    this.hideButtons.next('');
  }

  showSpinner(title: string) {
    this.showSpinnerOverlay.next(title);
  }

  hideSpinner() {
    this.hideSpinnerOverlay.next('');
  }

  showSuccessMessage(message: string) {
    this.snackBar.open(message, "", {
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ['snackbar-success'],
      duration: 6000
    });
  }

  showErrorMessage(message: string, action: string, duration = 12000) {
    if(!this.errorSnackbar) {
      this.errorSnackbar = this.snackBar.open(message, action, {
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['snackbar-error'],
        duration
      });
      this.errorSnackbar.afterDismissed().subscribe(() => {
        this.errorSnackbar = null;
      })
    }
  }

  previewVideo(id: number, orgId: number) {
    this.apiService.getVideoPreview(id, orgId).subscribe(v => {
      if (!v) return;
      this.dialog.open<VideoPreviewDialogComponent, VideoPreviewModalData>(VideoPreviewDialogComponent, { data: { previewVideo: v }, disableClose: false });
    })
  }

  previewForm(id: number, orgId: number) {
    this.apiService.getForm(orgId, id).subscribe((f) => {
      if (!f) return;
      let formDefinition = JSON.parse(f.data);
      this.dialog.open<FormPreviewDialogComponent, FormPreviewModalData>(FormPreviewDialogComponent, { data: { form: formDefinition }, disableClose: false });
    });
  }

  previewImage(id: number, orgId: number) {
    return;  // TODO: not yet implemented.
    /* this.apiService.getImage(orgId, id).subscribe((img) => {
      if (!f) return;
      this.dialog.open<ImagePreviewComponent, ImagePreviewModalData>(ImagePreviewComponent, { data: { url: img.url }, disableClose: false });
    }); */
  }

  previewTemplate(id: number, orgId: number, name : string) {
    this.apiService.getTemplateStepsList(orgId, id).subscribe((ts) => {
      this.dialog.open<PreviewStepsDialogComponent, PreviewStepsModalData>(PreviewStepsDialogComponent, {
        data: {
          previewName: name,
          theSteps: ts,
          orgId: orgId,
        },
        disableClose: false
      });
    });
  }

  async goFullScreen() {
    const elem = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    try {
        if (elem.requestFullscreen) {
          await elem.requestFullscreen();

        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          await elem.mozRequestFullScreen();

        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          await elem.webkitRequestFullscreen();

        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          await elem.msRequestFullscreen();

        } else { return false; }
        elem.classList.add("fullscreen");
        return true;
    } catch(err) {
      return false;
    }
  }

  async unFullScreen() {
    const elem = this.document;
    try {
      if (elem.exitFullScreen) {
        await elem.exitFullScreen();
      } else if (elem.mozCancelFullScreen) {
        /* Firefox */
        await elem.mozCancelFullScreen();
      } else if (elem.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        await elem.webkitExitFullscreen();
      } else if (elem.msExitFullscreen) {
        /* IE/Edge */
        await elem.msExitFullscreen();
      } else
        return false;
      elem.classList.remove("fullscreen");
      return true;
    } catch(err) {
      return false;
    }
  }

}
