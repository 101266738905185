import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MatCheckboxChange} from '@angular/material/checkbox';

interface Permission {
  permission_id: number;
  permission_name: string;
}

@Component({
  selector: 'app-permission-management',
  templateUrl: './permission-management.component.html',
  styleUrls: ['./permission-management.component.scss']
})
export class PermissionManagementComponent implements OnInit {
  @Input() groupsAvailable: [];
  @Input() permissionsAvailable: [] = [];
  @Input() permissionsAssigned: any [] = [];
  permissionsChanged = new BehaviorSubject<typeof this.permissionsAssigned>(this.permissionsAssigned);

  constructor() {
  }

  ngOnInit(): void {
  }

  isPermissionSelected(permissionName: string): boolean {
    return this.permissionsAssigned.some((permission: any) => permission.name === permissionName);
  }

  isGroupSelected(groupName: string): boolean {
    const group: any = this.groupsAvailable.find((group: any) => group.group_name === groupName);

    if (!group) {
      return false;
    }

    const groupPermissions = group.permissions.map((permission: any) => permission.permission_name);
    return groupPermissions.every((permissionName: string) =>
      this.permissionsAssigned.some((assignedPermission: any) => assignedPermission.name === permissionName)
    );
  }

  returnPermissions() {
    return this.permissionsAssigned;
  }

  onGroupChange($event: MatCheckboxChange) {

  }

  onPermissionChange($event: MatCheckboxChange) {
    let val = $event.source.value as unknown as Permission;
    let permission = {id: val.permission_id, name: val.permission_name};

    if ($event.source.checked &&
      !this.permissionsAssigned.some(p => p.id === permission.id)) {
      this.permissionsAssigned.push(permission);
    } else {
      this.permissionsAssigned = this.permissionsAssigned.filter(p => p.id !== permission.id);
    }

    this.permissionsChanged.next(this.permissionsAssigned);
  }
}
