import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';

// TODO: Define responses type
export type FormAnswersModalData = Pick<ModalData, "onClose"> & { responses: any[] };

@Component({
  selector: 'app-form-answers-dialog',
  templateUrl: './form-answers-dialog.component.html',
  styleUrls: ['./form-answers-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormAnswersDialogComponent {
  private dialog: boolean = undefined;

  constructor(
    private dialogRef: MatDialogRef<FormAnswersDialogComponent, FormAnswersModalData>,
    @Inject(MAT_DIALOG_DATA) public data: FormAnswersModalData
  ) {
    dialogRef.disableClose ??= true;
    if (this.data.onClose) this.dialogRef.afterClosed().subscribe(() => { this.data.onClose({ dialog: this.dialog }); });
  }

  closeDialog() {
    this.dialog = false;
    this.dialogRef.close();
  }
}
