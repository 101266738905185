import {Component, EventEmitter, Inject, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {InstructionSet, InstructionSetSectionItem, Provider} from '@core/models/pal-instruction-set';
import {Asset, FindAssetDialogComponent, FindAssetModalData} from '@app/shared/component/Dialog/find-asset-dialog/find-asset-dialog.component';
import {FormControl, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';
import { Media } from '../video-preview-dialog/video-preview-dialog.component';

export type ItemInfoModalData = Pick<ModalData, "onClose"> & {
  updateItem: (section: InstructionSet['InstructionSetSections'][number]['InstructionSetSectionItems'][number]) => any;
  item: InstructionSet['InstructionSetSections'][number]['InstructionSetSectionItems'][number];
  asset?: Media;
  instructionSet: InstructionSet
}

@Component({
  selector: 'app-item-info-modal-component',
  templateUrl: './item-info-modal.component.html'
})
export class ItemInfoModalComponent implements OnInit {
  private dialogResponse: boolean = undefined;
  public event: EventEmitter<any> = new EventEmitter();
  @Input() orgProviders: any[] = [];
  item: InstructionSetSectionItem;
  checked_providers: (Provider & { checked: boolean })[] = [];
  nameField = new FormControl('', {
    validators: [
      Validators.required,
    ],
  });

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ItemInfoModalComponent, ItemInfoModalData>,
    @Inject(MAT_DIALOG_DATA) public data: ItemInfoModalData
    ) {
      dialogRef.disableClose ??= true;
    }


  ngOnInit() {
    this.orgProviders = this.data.instructionSet.Providers;
    this.item = _.cloneDeep(this.data.item);

    if(this.item.name == "(No item name set, click edit to change)")
      this.item.name = "";

    for(let provider of this.orgProviders) {
      this.checked_providers.push({ id: provider.id, name: provider.name, checked: this.item.checked_by_default || !!this.item.Providers?.some(p => p.id === provider.id)});
    }
  }

  checkAllProviders() {
    if (this.item.checked_by_default) this.checked_providers.forEach(p => p.checked = true);
  }

  findVideo() {
    let data = { data: {
        setItem: (ret: Asset) => {
          this.item.name ||= ret.name;
          this.item.asset_id = ret.id;
          this.item.type = ret.type;

          if (!this.item.asset_id || !this.data.asset) this.data.asset = {} as Media;
          this.data.asset.src = ret.url;
          this.data.asset.thumbnail = ret.thumbnail_mq;
          this.data.asset.title = ret.name;
        }
      }
    }
    this.dialog.open<FindAssetDialogComponent, FindAssetModalData>(FindAssetDialogComponent, data);
  }

  updateItem() {
    this.dialogResponse = true;

    if(!this.item.asset_id || !this.data.asset) return
    if(this.item.name == "") {
      this.nameField.markAsTouched();
      this.nameField.markAsDirty();
      return;
    }

    this.item.Providers = this.checked_providers.filter(o => o.checked);
    if(this.item.name == "")
      this.item.name = "(No item name set, click edit to change)";
    this.data.updateItem(this.item);
    this.dialogRef.close();
  }

  hidePopup() {
    this.dialogRef.close();
  }
}
