<app-user-information #mainUser></app-user-information>

<div mat-dialog-content style="dislay: flex; flex-direction: column; margin-top: 1em;" *ngIf="false">
  <div>Profiles:</div>
  <div style="display: flex; gap: 15px;">
    <div style="display: flex; flex: 0 0 auto" class="profile-entry" *ngFor="let p of (profiles | async)" >
      <button [longPress]="1000" (onLongPress)="confirmDelete(p.id)" mat-raised-button color="primary" class="btn-profile" (click)="profileClick(p.id)">{{p.first_name}} {{p.last_name}}</button>
      <button class="hover-only xs-fab" color="primary" (click)="confirmDelete(p.id)" mat-mini-fab mat-button title="Click to delete this profile.">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

  </div>
  <div style="margin-top: 1em;">
    <button id="showCreateProfile" mat-raised-button color="accent"  (click)="profileClick(null)">Create Profile</button>
  </div>
</div>
