<div class="text-align: center" style="max-width: 35%; width: 35%; margin-left: auto; margin-right: auto; margin-top: 20%;">
  <div class="h1" style="width: 100%; text-align: center">Palaestra Platform Password Reset</div>
  <div class="h3" style="width: 100%; text-align: center">Enter a New Password</div>
  <form [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input type="password" matInput formControlName="password" title="Enter a new password, at least 6 characters long.">
      <div *ngIf="submitted && newPasswordForm.controls.password.errors">
        <mat-error *ngIf="newPasswordForm.controls.password.errors?.required">Password is required.</mat-error>
        <mat-error *ngIf="newPasswordForm.controls.password.errors?.minLength">Password must be at least 6 characters.</mat-error>
      </div>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Confirm Password</mat-label>
      <input type="password" matInput formControlName="confirmPassword" title="Enter the same password above to confirm you entered it properly.">
      <div *ngIf="submitted && newPasswordForm.controls.confirmPassword.errors">
        <mat-error *ngIf="newPasswordForm.controls.confirmPassword.errors?.required">Confirmation is required.</mat-error>
        <mat-error *ngIf="newPasswordForm.controls.confirmPassword.errors?.mustMatch">Passwords must match.</mat-error>
      </div>
    </mat-form-field>
    <div style="width: 100%; text-align: center">
      <button mat-raised-button color="primary" id="submit_button" type="submit">Update Password</button>
    </div>
  </form>
</div>

