import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {LandingModule} from './modules/landing/landing.module';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ContentLayoutComponent } from './core/layouts/content-layout/content-layout.component';
import { AdminLayoutComponent} from '@core/layouts/admin-layout/admin-layout.component';
import {MatSidenav} from '@angular/material/sidenav';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MainMaterialModule} from '@core/core/main-material.module';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgStreamingModule} from '@videogular/ngx-videogular/streaming';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import {NgxSelectModule} from 'ngx-select-ex';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {InterceptorService} from '@services/http_errors.service';
import { HeaderFieldType, LabelFieldType, SeparatorFieldType, TextFieldType} from '@shared/component/formly-custom-types/formly-custom-types.component';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { FormlyWrapperDrag } from './shared/wrapper/drag.wrapper';
import { FormlyWrapperDragList } from './shared/wrapper/drag-list.wrapper';
import {BrowserModule} from '@angular/platform-browser';
import {NgxSpinnerModule} from 'ngx-spinner';
import { AdminAuthComponent } from './modules/admin/admin-auth/admin-auth.component';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {QRCodeModule} from 'angularx-qrcode';
import {FormlyMatNativeSelectModule} from '@ngx-formly/material/native-select';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [
    AppComponent, AdminLayoutComponent,
    ContentLayoutComponent, HeaderFieldType, SeparatorFieldType, TextFieldType, LabelFieldType, AdminAuthComponent
  ],
  imports: [
    CommonModule, SharedModule, MaterialFileInputModule, MatDialogModule,
    DragDropModule, BrowserModule, NgxSpinnerModule,
    BrowserAnimationsModule,
    LandingModule, NgbDatepickerModule,
    AppRoutingModule,  NgxMaskModule.forRoot(),
    HttpClientModule, QRCodeModule,
    MainMaterialModule, VgCoreModule, VgStreamingModule, VgOverlayPlayModule, VgBufferingModule,
    VgControlsModule, ReactiveFormsModule, FormsModule, NgbModule, TagInputModule, NgxSelectModule,
    FormlyModule.forRoot({
      types: [
        { name: 'header', component: HeaderFieldType },
        { name: 'separator', component: SeparatorFieldType },
        { name: 'text', component: TextFieldType },
        { name: 'label', component: LabelFieldType }
      ],
      wrappers: [{ name: 'drag', component: FormlyWrapperDrag},
                 { name: 'drag-list', component: FormlyWrapperDragList}]
    }), FormlyMaterialModule,
    FormlyMatNativeSelectModule
  ],
  providers: [MatSidenav, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
  {
    provide: MatDialogRef,
    useValue: {}
  },
  {
    provide: MAT_DIALOG_DATA,
    useValue: {}
  },
  { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 9000 } },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
