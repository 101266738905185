<p align="center" style="margin-bottom: 0in; font-variant: small-caps; line-height: 115%">
  <font size="6" style="font-size: 24pt"><font face="Arial, serif"><font size="6" style="font-size: 22pt"><b>Privacy
    Policy</b></font></font></font></p>
<p class="western" style="margin-bottom: 0in; line-height: 100%"><br/>

</p>
<h1 class="western" align="center" style="margin-top: 0in; line-height: 115%">
  Palaestra Platform</h1>
<p class="western" style="margin-bottom: 0in; line-height: 115%"><font color="#999999"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Last
  updated </font></font></font><font color="#999999"><font face="Arial, serif"><font size="3" style="font-size: 12pt"><b>May
  17</b></font></font></font><font color="#999999"><sup><font face="Arial, serif"><font size="3" style="font-size: 12pt"><b>th</b></font></font></sup></font><font color="#999999"><font face="Arial, serif"><font size="3" style="font-size: 12pt"><b>,
  2018</b></font></font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%"><a name="_GoBack"></a>
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">TAG
    Tech, LLC</font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">,
    (“we” or “us” or “our”) respects the privacy of our users
    (“user” or “you”). This Privacy Policy explains how we
    collect, use, disclose, and safeguard your information when you visit
    our mobile application (the “Application”).</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
  </font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">
  </font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><b>
  </b></font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">Please
    read this Privacy Policy carefully.  IF YOU DO NOT AGREE WITH THE
    TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION. </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    reserve the right to make changes to this Privacy Policy at any time
    and for any reason.  We will alert you about any changes by updating
    the “Last updated” date of this Privacy Policy.  You are
    encouraged to periodically review this Privacy Policy to stay
    informed of updates. You will be deemed to have been made aware of,
    will be subject to, and will be deemed to have accepted the changes
    in any revised Privacy Policy by your continued use of the
    Application after the date such revised Privacy Policy is posted.  </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">This
    Privacy Policy does not apply to the third-party online/mobile store
    from which you install the Application or make payments, including
    any in-game virtual items, which may also collect and use data about
    you.  We are not responsible for any of the data collected by any
    such third party. </font></font>
</p>
<h1 class="western" style="line-height: 115%"><a name="_eenucvnqa0rq"></a>
  COLLECTION OF YOUR INFORMATION</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may collect information about you in a variety of ways.  The
    information we may collect via the Application depends on the content
    and materials you use, and includes:  </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_rima4gqmeezw"></a>
  Personal Data
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">Demographic
    and other personally identifiable information (such as your name and
    email address) that you voluntarily give to us when choosing to
    participate in various activities related to the Application, such as
  </font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">viewing
  data, watching videos, chat, interacting with managers</font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">.
  If you choose to share data about yourself via your profile, online
  chat, or other interactive areas of the Application, please be
  advised that all data you disclose in these areas is public and your
  data will be accessible to anyone who accesses the Application.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_qs8x7isf2hqt"></a>
  Derivative Data
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">Information
    our servers automatically collect when you access the Application,
    such as your native actions that are integral to the Application,
    including </font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">tracking
  watching videos, giving feedback, messaging</font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">,
  or replying to a post, as well as other interactions with the
  Application and other users via server log files.  </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_jmz6d47e30t"></a>
  Financial Data
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    you are an organizational owner, we collect financial information in
    order to bill you.  This information is stored securely in accordance
    to all privacy and data protection laws</font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">.
</font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">If
  you are not an organizational owner, no financial data is collected.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_z0yqukvj8bh9"></a>
  Facebook Permissions
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">The
    Application may by default access your </font></font><font color="#000080"><span lang="zxx"><u><a href="https://www.facebook.com/about/privacy/"><font color="#1155cc"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Facebook</font></font></font></a></u></span></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">
  basic account information, including your name, email, and profile
  picture URL, as well as other information that you choose to make
  public. We may also request access to other permissions related to
  your account, such as friends, and likes, and you may choose to grant
  or deny us access to each individual permission. For more information
  regarding Facebook permissions, refer to the </font></font><font color="#000080"><span lang="zxx"><u><a href="https://developers.facebook.com/docs/facebook-login/permissions"><font color="#1155cc"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Facebook
Permissions Reference </font></font></font></a></u></span></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">page.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_64xreagpas2f"></a>
  Data from Social Networks
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">User
    information from social networking sites, such as [Apple’s Game
    Center, Facebook, Google+ Instagram, Pinterest, Twitter], including
    your name, your social network username,  and public data for
    contacts, if you connect your account to such social networks. This
    information may also include the contact information of anyone you
    invite to use and/or join the Application.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_1wgqc2u1087n"></a>
  Geo-Location Information
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may request access or permission to and track location-based
    information from your mobile device, either continuously or while you
    are using the Application, to provide location-based services. If you
    wish to change our access or permissions, you may do so in your
    device’s settings.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_ohf32362ce60"></a>
  Mobile Device Access
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may request access or permission to certain features from your mobile
    device, including your mobile device’s bluetooth, calendar, camera,
    contacts, microphone, reminders, sensors,  social media accounts,
    storage and other features. </font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">If
  you wish to change our access or permissions, you may do so in your
  device’s settings.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_4iy4n37v9m63"></a>
  Mobile Device Data
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">Device
    information such as your mobile device ID number, model, and
    manufacturer, version of your operating system, phone number,
    country, location, and any other data you choose to provide.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_z2mmqno9ju37"></a>
  Push Notifications
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may request to send you push notifications regarding your account or
    the Application. If you wish to opt-out from receiving these types of
    communications, you may turn them off in your device’s settings.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_urn7verkgc6k"></a>
  Third-Party Data
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">Information
    from third parties, such as personal information or network friends,
    if you connect your account to the third party and grant the
    Application permission to access this information.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><br/>

</h2>
<h1 class="western" style="line-height: 115%"><a name="_v2uc2vjvvxe1"></a>
  USE OF YOUR INFORMATION</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">Having
    accurate information about you permits us to provide you with a
    smooth, efficient, and customized experience.  Specifically, we may
    use information collected about you via the Application to: </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<ol>
  <li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Administer
      content in the application.</font></font></p></li>
  <li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Assist
      law enforcement and respond to subpoena.</font></font></p></li>
  <li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Compile
      anonymous statistical data and analysis for use internally or with
      third parties. </font></font>
  </p></li>
  <li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Create
      and manage your account.</font></font></p>
  </li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Enable
      user-to-user communications.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Generate
      a personal profile about you to make future visits to the
      Application more personalized.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Increase
      the efficiency and operation of the Application.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Monitor
      and analyze usage and trends to improve your experience with the
      Application.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Notify
      you of updates to the Application.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Perform
      other business activities as needed.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Prevent
      fraudulent transactions, monitor against theft, and protect against
      criminal activity.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Request
      feedback and contact you about your use of the Application. </font></font>
  </p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Resolve
      disputes and troubleshoot problems.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Respond
      to product and customer service requests.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Send
      you periodic updates.</font></font></p>
</li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Solicit
      support for the Application.</font></font></p>
</ol>
<p class="western" align="justify" style="margin-left: 0.75in; margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h1 class="western" style="line-height: 115%"><a name="_klddry1k9fe6"></a>
  DISCLOSURE OF YOUR INFORMATION</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may share information we have collected about you in certain
    situations. Your information may be disclosed as follows: </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_wmqiyk8z1ifq"></a>
  By Law or to Protect Rights
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    we believe the release of information about you is necessary to
    respond to legal process, to investigate or remedy potential
    violations of our policies, or to protect the rights, property, and
    safety of others, we may share your information as permitted or
    required by any applicable law, rule, or regulation.  This includes
    exchanging information with other entities for fraud protection and
    credit risk reduction.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_fae8kjk8qmdd"></a>
  Third-Party Service Providers
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may share your information with third parties that perform services
    for us or on our behalf, including payment processing, data analysis,
    email delivery, hosting services, customer service, and marketing
    assistance.  </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_m891eoq353db"></a>
  Marketing Communications</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">With
    your consent, or with an opportunity for you to withdraw consent, we
    may share your information with third parties for marketing purposes,
    as permitted by law.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_1g4s611s98t6"></a>
  Interactions with Other Users
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    you interact with other users of the Application, those users may see
    your name, profile photo, and descriptions of your activity,
    including sending invitations to other users, chatting with other
    users, liking posts, following blogs. </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_qni5gjh20gty"></a>
  Online Postings</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">When
    you post comments, contributions or other content to the
    Applications, your posts may be viewed by all users and may be
    publicly distributed outside the Application in perpetuity</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_w1o99isgvu3t"></a>
  Third-Party Advertisers
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may use third-party advertising companies to serve ads when you visit
    the Application. These companies may use information about your
    visits to the Application and other websites that are contained in
    web cookies in order to provide advertisements about goods and
    services of interest to you. </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%"><a name="_wvhkv7isu1op"></a>
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_dpeckra1seq2"></a>
  Affiliates
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may share your information with our affiliates, in which case we will
    require those affiliates to honor this Privacy Policy. Affiliates
    include our parent company and any subsidiaries, joint venture
    partners or other companies that we control or that are under common
    control with us.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_mlwpc7lo5h0z"></a>
  Business Partners
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may share your information with our business partners to offer you
    certain products, services or promotions. </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_1b3lbtrwlnlf"></a>
  Social Media Contacts
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    you connect to the Application through a social network, your
    contacts on the social network will see your name, profile photo, and
    descriptions of your activity.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_j770ddi8r35t"></a>
  Other Third Parties</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may share your information with advertisers and investors for the
    purpose of conducting general business analysis. We may also share
    your information with such third parties for marketing purposes, as
    permitted by law. </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_gfgj94limquo"></a>
  Sale or Bankruptcy
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    we reorganize or sell all or a portion of our assets, undergo a
    merger, or are acquired by another entity, we may transfer your
    information to the successor entity.  If we go out of business or
    enter bankruptcy, your information would be an asset transferred or
    acquired by a third party.  You acknowledge that such transfers may
    occur and that the transferee may decline honor commitments we made
    in this Privacy Policy. </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    are not responsible for the actions of third parties with whom you
    share personal or sensitive data, and we have no authority to manage
    or control third-party solicitations.  If you no longer wish to
    receive correspondence, emails or other communications from third
    parties, you are responsible for contacting the third party directly.
  </font></font>
</p>
<h1 class="western" style="line-height: 115%"><a name="_p6377q2xgso8"></a>
  TRACKING TECHNOLOGIES</h1>
<h2 class="western" style="line-height: 115%"><a name="_56emerdysgpo"></a>
  Cookies and Web Beacons</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may use cookies, web beacons, tracking pixels, and other tracking
    technologies on the Application to help customize the Application and
    improve your experience. When you access the Application, your
    personal information is not collected through the use of tracking
    technology. Most browsers are set to accept cookies by default. You
    can remove or reject cookies, but be aware that such action could
    affect the availability and functionality of the Application. You may
    not decline web beacons. However, they can be rendered ineffective by
    declining all cookies or by modifying your web browser’s settings
    to notify you each time a cookie is tendered, permitting you to
    accept or decline cookies on an individual basis.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_4fc8mj1uajxp"></a>
  Internet-Based Advertising</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    use no outside advertising.  No tracking goes to outside advertisers.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_n6oeggdkjcgr"></a>
  Website Analytics
</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    may also partner with selected third-party vendors, such as </font></font><font color="#000080"><span lang="zxx"><u><a href="https://support.google.com/analytics/answer/6004245?hl=en"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Google
Analytics</font></font></a></u></span></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">,
  to allow tracking technologies and remarketing services on the
  Application through the use of first party cookies and third-party
  cookies, to, among other things, analyze and track users’ use of
  the Application, determine the popularity of certain content, and
  better understand online activity. By accessing the Application, you
  consent to the collection and use of your information by these
  third-party vendors. You are encouraged to review their privacy
  policy and contact them directly for responses to your questions. We
  do not transfer personal information to these third-party vendors.
  However, if you do not want any information to be collected and used
  by tracking technologies, you can install and/or update your settings
  for one of the following: </font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">[</font></font><font color="#000080"><span lang="zxx"><u><a href="https://tools.google.com/dlpage/gaoptout/"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Google
Analytics Opt-Out Plugin</font></font></a></u></span></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">]
</font></font>
</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">[</font></font><font color="#000080"><span lang="zxx"><u><a href="https://www.google.com/settings/u/0/ads/authenticated?hl=en"><font face="Arial, serif"><font size="2" style="font-size: 11pt">Google
Ads Settings Page</font></font></a></u></span></font><font face="Arial, serif"><font size="2" style="font-size: 11pt"><u>]</u></font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">You
    should be aware that getting a new computer, installing a new
    browser, upgrading an existing browser, or erasing or otherwise
    altering your browser’s cookies files may also clear certain
    opt-out cookies, plug-ins, or settings.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h1 class="western" style="line-height: 115%"><a name="_hs1jfy8yvwt0"></a>
  THIRD-PARTY WEBSITES</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">The
    Application may contain links to third-party websites and
    applications of interest, including advertisements and external
    services, that are not affiliated with us. Once you have used these
    links to leave the Application, any information you provide to these
    third parties is not covered by this Privacy Policy, and we cannot
    guarantee the safety and privacy of your information. Before visiting
    and providing any information to any third-party websites, you should
    inform yourself of the privacy policies and practices (if any) of the
    third party responsible for that website, and should take those steps
    necessary to, in your discretion, protect the privacy of your
    information. We are not responsible for the content or privacy and
    security practices and policies of any third parties, including other
    sites, services or applications that may be linked to or from the
    Application.</font></font></p>
<h1 class="western" style="line-height: 115%"><a name="_odf08ny3ibiq"></a>
  SECURITY OF YOUR INFORMATION</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    use administrative, technical, and physical security measures to help
    protect your personal information.  While we have taken reasonable
    steps to secure the personal information you provide to us, please be
    aware that despite our efforts, no security measures are perfect or
    impenetrable, and no method of data transmission can be guaranteed
    against any interception or other type of misuse.  Any information
    disclosed online is vulnerable to interception and misuse by
    unauthorized parties.  Therefore, we cannot guarantee complete
    security if you provide personal information.</font></font></p>
<h1 class="western" align="left" style="line-height: 115%"><a name="_fnsu8nbgwwh0"></a>
  POLICY FOR CHILDREN</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">We
    do not knowingly solicit information from or market to children under
    the age of 13. If you become aware of any data we have collected from
    children under age 13, please contact us using the contact
    information provided below. </font></font>
</p>
<h1 class="western"><a name="_lchlm3ya7ak"></a>CONTROLS FOR
  DO-NOT-TRACK FEATURES
</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">Most
    web browsers and some mobile operating systems [and our mobile
    applications] include a Do-Not-Track (“DNT”) feature or setting
    you can activate to signal your privacy preference not to have data
    about your online browsing activities monitored and collected.  No
    uniform technology standard for recognizing and implementing DNT
    signals has been finalized. As such, we do not currently respond to
    DNT browser signals or any other mechanism that automatically
    communicates your choice not to be tracked online.  If a standard for
    online tracking is adopted that we must follow in the future, we will
    inform you about that practice in a revised version of this Privacy
    Policy.  </font></font>
</p>
<h1 class="western" style="line-height: 115%"><a name="_oynxufe0hy32"></a>
  OPTIONS REGARDING YOUR INFORMATION</h1>
<h2 class="western" style="line-height: 115%">Account Information</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">You
    may at any time review or change the information in your account or
    terminate your account by:</font></font></p>
<ul>
  <li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Logging
      into your account settings and updating your account</font></font></p>
  </li><li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Contacting
      us using the contact information provided below</font></font></p>
</ul>
<p class="western" align="justify" style="margin-left: 0.75in; margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">Upon
    your request to terminate your account, we will deactivate or delete
    your account and information from our active databases. However, some
    information may be retained in our files to prevent fraud,
    troubleshoot problems, assist with any investigations, enforce our
    Terms of Use and/or comply with legal requirements.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<h2 class="western" style="line-height: 115%"><a name="_3mc89ff9i0fu"></a>
  Emails and Communications</h2>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    you no longer wish to receive correspondence, emails, or other
    communications from us, you may opt-out by:</font></font></p>
<ul>
  <li>
  <p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
    <font face="Arial, serif"><font size="2" style="font-size: 11pt">Contacting
      us using the contact information provided below</font></font></p>
  </li>
</ul>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    you no longer wish to receive correspondence, emails, or other
    communications from third parties, you are responsible for contacting
    the third party directly. </font></font>
</p>
<h1 class="western" style="line-height: 115%">
  CALIFORNIA PRIVACY RIGHTS</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">California
    Civil Code Section 1798.83, also known as the “Shine The Light”
    law, permits our users who are California residents to request and
    obtain from us, once a year and free of charge, information about
    categories of personal information (if any) we disclosed to third
    parties for direct marketing purposes and the names and addresses of
    all third parties with which we shared personal information in the
    immediately preceding calendar year. If you are a California resident
    and would like to make such a request, please submit your request in
    writing to us using the contact information provided below.</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    you are under 18 years of age, reside in California, and have a
    registered account with the Application, you have the right to
    request removal of unwanted data that you publicly post on the
    Application. To request removal of such data, please contact us using
    the contact information provided below, and include the email address
    associated with your account and a statement that you reside in
    California.  We will make sure the data is not publicly displayed on
    the Application, but please be aware that the data may not be
    completely or comprehensively removed from our systems.</font></font></p>
<h1 class="western" align="left" style="line-height: 115%">
  CONTACT US</h1>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">If
    you have questions or comments about this Privacy Policy, please
    contact us at:</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <font face="Arial, serif"><font size="2" style="font-size: 11pt">privacy@</font></font><font face="Arial, serif"><font size="2" style="font-size: 11pt">palaestra.app</font></font></p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">


</p>
<p class="western" align="justify" style="margin-bottom: 0in; line-height: 115%">
  <br/>

</p>
