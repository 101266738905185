import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskItemComponent } from '@shared/component/task-item/task-item.component';
import { UserInformationComponent } from '@shared/component/user-information/user-information.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { PermissionManagementComponent } from './component/permission-management/permission-management.component';
import { MainMaterialModule } from '@core/core/main-material.module';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TagsComponent } from './component/tags/tags.component';
import { PalListItemComponent } from './component/pal-list-item/pal-list-item.component';
import { FindAssetNamePipe } from '@shared/pipes/find-asset-name.pipe';
import { FormStepComponent } from './component/form-step/form-step.component';
import { FormlyModule } from '@ngx-formly/core';
import { NgxMaskModule } from 'ngx-mask';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { PalListItem2Component } from './component/pal-list-item2/pal-list-item2.component';
import { EditProtocolComponent, OrgProtocolDaySlot } from './component/edit-protocol/edit-protocol.component';
import { SecondaryContactsComponent } from './component/secondary-contacts/secondary-contacts.component';
import { NotificationStatusesComponent } from './component/notification-statuses/notification-statuses.component';
import { SmsStatusComponent } from './component/notification-statuses/sms-status/sms-status/sms-status.component';
import { EmailStatusComponent } from './component/notification-statuses/email-status/email-status/email-status.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AssignComponent } from './component/assign/assign.component';
import { AssetAttributesComponent } from './component/asset-attributes/asset-attributes.component';
import { AssetComponent } from './component/asset/asset.component';
import { AssetSearchBarComponent } from './component/asset-search-bar/asset-search-bar.component';
import { AssetGridListDisplayComponent } from './component/asset-grid-list-display/asset-grid-list-display.component';
import {
  FindAssetDialogComponent,
  FindAssetForLibraryComponent,
  AddMemberDialogComponent,
  FormDialogComponent,
  FindOrganizationsDialogComponent,
  ItemInfoModalComponent,
  VideoPreviewDialogComponent,
  FormAnswersDialogComponent,
  FormPreviewDialogComponent,
  PreviewStepsDialogComponent,
  ProtocolTaskOptionsModalComponent,
  ResendTaskDialogComponent,
} from '@shared/component/Dialog/';
import { ProtocolWeekTitlePipe } from './pipes/protocol-week-title.pipe';
import { ChangeDateModalComponent } from './component/change-date-modal/change-date-modal.component';
import { FilterPipe } from './pipes/filter.pipe';

@NgModule({
  declarations: [
    AddMemberDialogComponent,
    AssignComponent,
    AssetAttributesComponent,
    AssetComponent,
    AssetGridListDisplayComponent,
    AssetSearchBarComponent,
    ChangeDateModalComponent,
    EmailStatusComponent,
    EditProtocolComponent,
    FindAssetDialogComponent,
    FindAssetForLibraryComponent,
    FindAssetNamePipe,
    FindOrganizationsDialogComponent,
    FilterPipe,
    FormAnswersDialogComponent,
    FormDialogComponent,
    FormPreviewDialogComponent,
    FormStepComponent,
    ItemInfoModalComponent,
    NotificationStatusesComponent,
    OrgProtocolDaySlot,
    PalListItem2Component,
    PalListItemComponent,
    PermissionManagementComponent,
    PhoneNumberPipe,
    PreviewStepsDialogComponent,
    ProtocolTaskOptionsModalComponent,
    ProtocolWeekTitlePipe,
    ResendTaskDialogComponent,
    SecondaryContactsComponent,
    SmsStatusComponent,
    TagsComponent,
    TaskItemComponent,
    UserInformationComponent,
    VideoPreviewDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VgCoreModule,
    VgStreamingModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgControlsModule,
    MainMaterialModule,
    NgbDatepickerModule,
    FlexLayoutModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormlyModule,
    NgxMaskModule,
    DragDropModule
  ],
  exports: [
    TaskItemComponent,
    UserInformationComponent,
    VideoPreviewDialogComponent,
    TagsComponent,
    PalListItemComponent,
    FindAssetNamePipe,
    FilterPipe,
    FormStepComponent,
    PhoneNumberPipe,
    PalListItem2Component,
    EditProtocolComponent,
    SecondaryContactsComponent,
    PreviewStepsDialogComponent,
    AssignComponent,
    AssetAttributesComponent,
    AssetComponent,
    AssetSearchBarComponent,
    AssetGridListDisplayComponent,
  ],
})
export class SharedModule {}
