<div style="width: 100%" class="mat-headline">Permissions</div>
<div style="display: flex; flex-direction: row; gap: 20px;">
  <div *ngFor="let group of groupsAvailable; let i=index">
    <mat-checkbox style="margin-bottom: 24px; font-size: 20px; font-weight: 500;" [value]="group.group_name"
                  [checked]="this.isGroupSelected(group.group_name)" (change)="onGroupChange($event)">{{ group.group_name }}
    </mat-checkbox>
    <div style="display: flex; flex-direction: column;">
      <mat-checkbox *ngFor="let permission of group.permissions" style="margin-left: 20px; margin-bottom: 24px"
                    [value]="permission"
                    [checked]="this.isPermissionSelected(permission.permission_name)" (change)="onPermissionChange($event)">{{ permission.permission_name }}
      </mat-checkbox>
    </div>
  </div>
</div>
