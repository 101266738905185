import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '@services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UnsavedInformationGuard implements CanDeactivate<unknown> {

  constructor(private userService: UserService) {}

  async canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if(!this.userService.unsavedInfo)
      return true;

    let deactivate = await this.userService.unsavedChangeMessage();
    return deactivate;
  }

}
