import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import {ENTER, TAB} from '@angular/cdk/keycodes';
import {PageService} from '@services/page.service';

@Component({
  selector: 'app-secondary-contacts',
  templateUrl: './secondary-contacts.component.html',
  styleUrls: ['./secondary-contacts.component.scss']
})
export class SecondaryContactsComponent implements OnInit {

  @Input() secondaryContacts: string[] = [];
  @Output() secondaryContactChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() item: string = "task";

  addOnBlur: boolean = true;
  separatorKeysCodes: number[] = [ENTER,TAB];

  constructor(private pageService: PageService) { }

  ngOnInit(): void {
  }

  removeSecondary(s: string) {
    this.secondaryContacts.splice(this.secondaryContacts.indexOf(s), 1);
    this.secondaryContactChange.emit(true);
  }

  addSecondary($event: MatChipInputEvent) {
    let value = $event.value;
    if(!value)
      return;
    // email regex: "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"

    if(value.length == 10 && /^[0-9]+$/.test(value)) {
      // this is a phone number, format it.
      let match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        value = '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    } else if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
      // email, value is ok.
      let i = 0;
    } else {
      value = null;
      this.pageService.showErrorMessage("Invalid email or phone number.", "Ok");
    }

    if(value) {
      this.secondaryContacts.push(value);
      $event.chipInput!.clear();
      this.secondaryContactChange.emit(true);
    }

  }

}
