import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {liveSearch} from '@shared/component/livesearch/livesearch';
import {UserService} from '@services/user.service';
import {ListItemEventService} from '@services/list-item-event.service';
import {PalLibraryItem} from '@core/models/pal-library-item';
import {PageService} from '@services/page.service';
import { ModalData } from '@app/services/modal.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type Asset = {
  id: number;
  video_url: string;
  url: string;
  owner_id: number;
  my_org_id: number;
  type: "video";
  description: string;
  thumbnail_mq: string;
  tags: string[];
  name: string;
  usage_count: number;
} & ({
  licensed: true;
  organization_name: string;
} | {
  licensed: false;
  organization_name: ""
})

export type FindAssetModalData = Pick<ModalData, "onClose"> & {
  setItem: (item: Asset) => any;
}

@Component({
  selector: 'app-find-asset-dialog',
  templateUrl: './find-asset-dialog.component.html',
  styleUrls: ['./find-asset-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindAssetDialogComponent implements OnInit, OnDestroy {
  private dialog: boolean = undefined;
  currentItem: any;
  private searchSubject = new BehaviorSubject<string>('');
  libraryItems: Observable<PalLibraryItem[]>;
  orgId: number;
  public selectedItem: Subject<PalLibraryItem>;
  private subs: Subscription[] = [];

  constructor(private lis: ListItemEventService,
              private userService: UserService, private pageService: PageService,
              private dialogRef: MatDialogRef<FindAssetDialogComponent, FindAssetModalData>,
              @Inject(MAT_DIALOG_DATA) public data: FindAssetModalData
              ) {
                dialogRef.disableClose ??= true;
                if (this.data.onClose) this.dialogRef.afterClosed().subscribe(() => { this.data.onClose({ dialog: this.dialog }); });
              }

  ngOnInit(): void {
    this.selectedItem  = new Subject<PalLibraryItem>();
    let org = this.userService.currentOrgObserver.value;
    this.orgId = org.id;
    this.libraryItems = this.searchSubject.pipe(
      liveSearch(query => this.userService.searchForAssignableItems(this.orgId, query))
    );

  }


  ngOnDestroy(): void {
    this.subs.forEach(s => {s.unsubscribe()});
  }

  buttonClick(evt) {
    let ids = evt.data.split('_');
    if(ids[0] == "image")
      this.previewImage(ids[1]);
    else if(ids[0] == "form")
      this.previewForm(ids[1]);
    if(ids[0] == "video")
      this.previewVideo(ids[1]);
  }

  searchItems(needle: string) {
    this.searchSubject.next(needle);
  }

  selectItem(item) {
    this.currentItem = item;
  }

  confirmItem() {
    this.dialog = true;
    if(this.currentItem) {
      this.data.setItem(this.currentItem);
      this.dialogRef.close();
    }
  }

  previewForm(id: number) {
    this.pageService.previewForm(id, this.orgId);
  }

  previewImage(id: number) {
    this.pageService.previewImage(id, this.orgId);
  }

  previewVideo(id: number) {
    this.pageService.previewVideo(id, this.orgId);
  }

  hideModal() {
    this.dialog = false;
    this.dialogRef.close();
  }
}
