import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MediaObserver} from '@angular/flex-layout';
import {PageService} from '@services/page.service';
import {MatSidenav} from '@angular/material/sidenav';
import {UserService} from '@services/user.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AuthService} from '@services/auth.service';
import {ActivatedRoute, ActivationStart, GuardsCheckEnd, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import { PermissionService } from '@app/services/permission.service';
import {PalOrganization} from '@core/models/pal-organization';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  title: string;
  currentModule: string = "";
  mode: string = 'side';
  opened: boolean = true;
  organizations: PalOrganization[];
  subs: Subscription [] = [];
  modals: number = 0;
  org: any = null;
  showCancel: boolean = false;
  showDelete: boolean = false;
  currentOrg: Observable<PalOrganization>;
  inOrg: boolean = false;
  showSave: boolean = false;
  spinnerTitle: string = "";
  adminArea: boolean = false;
  isAdminUser: boolean = false;


  constructor(private activatedRoute: ActivatedRoute,  public permissionService: PermissionService, private location: Location, private router: Router, private auth: AuthService, private ref: ChangeDetectorRef, private sidenav: MatSidenav,
              public media: MediaObserver, private pageService: PageService, private userService: UserService, private breakpointObserver: BreakpointObserver,
              private spinner: NgxSpinnerService) {

    this.subs.push(this.pageService.subject.subscribe((newTitle: string) => {
      this.title = newTitle;
    }));
    this.subs.push(this.router.events.subscribe((ev) => {
      if(ev instanceof ActivationStart ) {
        this.showCancel = false;
        this.showSave = false;
        this.showDelete = false;
      }
      // this should go into the canActivate guard.
      if (ev instanceof NavigationEnd && ev.url != "/") {
        this.permissionService.loadPermissions();
      }

    }));
    this.subs.push(this.pageService.showCancel.subscribe(() => {
      this.showCancel = true;
    }));
    this.subs.push(this.pageService.showDelete.subscribe(() => {
      this.showDelete = true;
    }));
    this.subs.push(this.pageService.showSave.subscribe(() => {
      this.showSave = true;
    }));
    this.subs.push(this.pageService.showSpinnerOverlay.subscribe((title : string) => {
      this.spinnerTitle = title;
      this.spinner.show();
    }));
    this.subs.push(this.pageService.hideSpinnerOverlay.subscribe(() => {
      this.spinnerTitle = "";
      this.spinner.hide();
    }));
    this.subs.push(this.pageService.hideButtons.subscribe(() => {
      this.showSave = false;
      this.showCancel = false;
      this.showDelete = false;
    }));
    this.currentOrg = this.userService.currentOrgObserver;

    this.subs.push(this.userService.currentModuleObserver.subscribe(x => {
      if(x === 'my-tasks' || x === 'my-profile') {
        this.userService.setCurrentOrg(null);
        this.inOrg = false;
      }
      else
        this.inOrg = true;

      if(x === 'video' || x === 'image' || x === 'form')
        this.currentModule = 'library';
      else if (x === 'invite_member')
        this.currentModule = 'members';
      else if (x === 'protocol')
        this.currentModule = 'protocols';
      else if (x === 'report' || x === 'reports')
        this.currentModule = 'reports';
      else
        this.currentModule = x;

    }));
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({orgs}) => {
      this.organizations = orgs;
      this.isAdminUser = orgs.filter(o => o.id === 15);
    });
  }

  async logout() {
    await this.auth.logout();
    this.router.navigate(['/']);
  }

  ngAfterViewInit(): void {

    window.dispatchEvent(new Event('resize'));
    if (!this.media.isActive(Breakpoints.Handset) ) {
      this.opened = true;
      this.sidenav.mode = this.mode = 'side';
      this.sidenav.open();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  goBack() {
    this.location.back();
  }

  emitSave() {
    this.pageService.saveEvent.next('');
  }

  emitDelete() {
    this.pageService.deleteEvent.next('');
  }
}
