<form [formGroup]="userForm" style="display: flex; flex-direction: column; max-width: 100%;">
  <div style="display: flex; flex-direction: row;">
    <div id="contact_info" style="display: flex; flex-direction: column; flex: 0.5 0.5 auto;">
      <div style="width: 100%" class="mat-headline">Personal Information</div>
      <div style="display: flex; flex-direction: row; gap: 16px;">
        <div style="display: flex; flex: 1 1 auto;" >
          <mat-form-field style="width: 100%">
            <mat-label>First Name</mat-label>
            <input id="firstName" matInput placeholder="" formControlName="first_name" type="text" required autocomplete="off">
            <mat-error class="" *ngIf="userForm.controls.first_name.invalid">
              First name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div style="display: flex; flex: 1 1 auto">
          <mat-form-field style="width: 100%">
            <mat-label>Last Name</mat-label>

            <input matInput placeholder="" formControlName="last_name" type="text" required autocomplete="off">
            <mat-error class="" *ngIf="userForm.controls.last_name.invalid">
              Last name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; gap: 16px;">
        <div style="display: flex; flex: 1 1 auto;" >
          <mat-form-field style="width: 100%" >
            <mat-label>Email Address</mat-label>
            <input matInput placeholder="" formControlName="email" type="text" (input)="contactChanged()" autocomplete="off">
            <mat-error *ngIf="userForm.controls.email.invalid" class="" >
              Email or Phone Number is required
            </mat-error>
          </mat-form-field>
        </div>
        <div style="display: flex; flex: 1 1 auto;">
          <mat-form-field style="flex: 100%" >
          <mat-label>Phone</mat-label>

          <input matInput placeholder="" [mask]="'(000) 000-0000'" formControlName="phone_number" type="text" (input)="contactChanged()" autocomplete="off">

            <mat-error *ngIf="userForm.controls.phone_number.invalid" class="">
              Email or Phone Number is required
            </mat-error>

        </mat-form-field>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; gap: 16px;">
        <div style="display: flex; flex: 1 1 auto;">
          <mat-form-field style="width: 100%" >
            <mat-label>Preferred Name</mat-label>
            <input matInput placeholder="Optional Display Name" formControlName="display_name" type="text">
          </mat-form-field>
          </div>
      </div>
    </div>
    <div id="notifications_preferences">
      <div style="width: 100%" class="mat-headline">Notification Preferences</div>
      <div style="padding-top: 2em; display: flex; flex-direction: column;" >
        <mat-checkbox formControlName="comm_email" style="margin-left: 20px; margin-bottom: 24px">Allow Email Communications</mat-checkbox>
        <mat-checkbox formControlName="comm_text"  style="margin-left: 20px;">Allow Text Communications</mat-checkbox>
      </div>
    </div>
  </div>

  <div style="padding-top: 2em; display: flex; flex-direction: column;" *ngIf="!showTaskArea || false">
    <mat-checkbox *ngIf="_show_trainer" style="margin-left: 20px;" formControlName="is_trainer" >User is a Manager</mat-checkbox>
  </div>

  <div style="margin-top: 1em; width: 100%" class="mat-headline" *ngIf="(_user?.id && _user?.is_trainer) || options?.inputPassword">Security</div>
  <div style="display: flex; width: 50%; flex-direction: row; gap: 16px;" *ngIf="_user?.id || options?.inputPassword">
    <div style="display: flex; flex: 1 1 auto;">
      <mat-form-field style="width: 100%" >
        <mat-label>{{ options?.inputPassword ? 'Set' : 'New' }} Password</mat-label>
        <input matInput placeholder="" formControlName="new_password" type="password" [required]="!_user.id && options?.inputPassword" autocomplete="off">
        <mat-error *ngIf="userForm.get('new_password').hasError('required')">
          Password is required.
        </mat-error>
        <mat-error *ngIf="userForm.hasError('passwordMismatch')">
          New password and confirmation must be the same.
        </mat-error>
      </mat-form-field>
    </div>
    <div style="display: flex; flex: 1 1 auto;">
      <mat-form-field style="width: 100%" >
        <mat-label>Confirm</mat-label>

        <input matInput placeholder="" formControlName="password_confirmation" type="password" [required]="!_user.id && options?.inputPassword" autocomplete="off">
        <mat-error *ngIf="userForm.get('password_confirmation').hasError('required')">
          Password is required.
        </mat-error>
        <mat-error *ngIf="userForm.hasError('passwordMismatch')">
          New password and confirmation must be the same.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="userForm.controls.is_trainer.value" style="margin-top: 1em; display: flex; flex-direction: column;">
    <app-permission-management #permissionsComponent [groupsAvailable]="permissions" [permissionsAssigned]="_user.permissions"></app-permission-management>
  </div>
</form>

