import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@app/shared/component/Dialog/error-dialog/error-dialog.component';
import { MessageDialogComponent } from '@app/shared/component/Dialog/message-dialog/message-dialog.component';
import { QuestionDialogComponent } from '@app/shared/component/Dialog/question-dialog/question-dialog.component';

export interface ModalData {
  /**
   * @description The main text displayed on the modal
   */
  prompt: string;
  preformat?: string;
  /**
   * @description Callback function when the user has explicitly clicked a button, it doesn't execute if the user dismiss the modal clicking outside
   */
  onResponse?: ({ dialog }: { dialog: boolean }) => any;
  /**
   * @description Callback function when the modal is closed, runs after `onResponse`
   * @param dialog `true` if the [Ok/Yes] button was clicked, `false` if the [Cancel/No] button was clicked, `undefined` if the modal was dismissed
   */
  onClose?: ({ dialog }: { dialog: boolean | undefined }) => any;
}

/**
 * @default { yesButtonText: "Yes", noButtonText: "No" }
 */
export type ModalQuestionData = Omit<ModalData, "preformat"> & { 
  yesButtonText?: string; 
  noButtonText?: string; 
};

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private dialog: MatDialog) {}

  /**
   * @default {config.disableClose: true} - The `disableClose` property defaults to true if not provided explicitly in the `config` object.
   */
  showMessageDialog(config: MatDialogConfig<ModalData> & { data: ModalData }): MatDialogRef<MessageDialogComponent, ModalData> {
    config.disableClose ??= true;
    return this.dialog.open<MessageDialogComponent, ModalData>(MessageDialogComponent, config);
  }

  /**
   * @default {config.disableClose: true} - The `disableClose` property defaults to true if not provided explicitly in the `config` object.
   */
  showErrorDialog(config: MatDialogConfig<ModalData> & { data: ModalData }): MatDialogRef<ErrorDialogComponent, ModalData> {
    config.disableClose ??= true;
    return this.dialog.open<ErrorDialogComponent, ModalData>(ErrorDialogComponent, config);
  }
  
  /**
   * @default {config.disableClose: true} - The `disableClose` property defaults to true if not provided explicitly in the `config` object.
   * @default {config.data.yesButtonText} Defaults to "Yes" if not provided in the `config.data` object.
   * @default {config.data.noButtonText} Defaults to "No" if not provided in the `config.data` object.
   */
  showQuestionDialog(config: MatDialogConfig<ModalQuestionData> & { data: ModalQuestionData }): MatDialogRef<QuestionDialogComponent, ModalQuestionData> {
    config.disableClose ??= true;
    return this.dialog.open<QuestionDialogComponent, ModalQuestionData>(QuestionDialogComponent, config);
  }
}
