<div mat-dialog-content style="display: flex; flex-direction: column; height: 100%;">
  <h2 mat-dialog-title style="display: flex; flex: 0 0 auto;">Form Responses</h2>
  <cdk-virtual-scroll-viewport autosize itemSize="56" orientation="vertical" style="display: flex;flex: 1 1 auto">
    <div class="answer-separator"*cdkVirtualFor="let r of data.responses">
      <app-form-step  [fields]="r.form_definition" [formValues]="r.answers" [readOnly]="true"></app-form-step>
    </div>
  </cdk-virtual-scroll-viewport>
  <div mat-dialog-actions style="display: flex; flex: 0 0 auto">
    <button type="button" mat-raised-button color="primary" (click)="closeDialog()">Close</button>
  </div>
</div>
