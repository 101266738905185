import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';


@Component({
  selector: 'formly-wrapper-reorder',
  template: `

    <ng-container #fieldComponent>
    </ng-container>

    `,
    standalone: true,
    imports: [MatIconModule, MatButtonModule, CommonModule, DragDropModule],
})
export class FormlyWrapperDrag extends FieldWrapper {

}
