import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';
import {FormControl} from '@angular/forms';

export type ProtocolTaskOptionsModalData = Pick<ModalData, "onClose"> & {
  updateItem: (task: any) => any;
  due_date_type: number;
  due_date_offset: number;
  pre_post: boolean;
}

@Component({
  selector: 'app-protocol-task-options-modal-component',
  templateUrl: './protocol-task-options-modal.component.html',
  styleUrls: ['./protocol-task-options-modal.component.scss'],

})
export class ProtocolTaskOptionsModalComponent implements OnInit, OnDestroy {
  public event: EventEmitter<any> = new EventEmitter();
  public dueDateType = new FormControl(0);
  public dueDateOffset = new FormControl(0);

  @Input() setProviders: any[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ProtocolTaskOptionsModalComponent, ProtocolTaskOptionsModalData>,
    @Inject(MAT_DIALOG_DATA) public data: ProtocolTaskOptionsModalData
    ) {
      dialogRef.disableClose ??= true;
    }


  ngOnInit() {
    this.dueDateType.patchValue(this.data.due_date_type);
    this.dueDateOffset.patchValue(this.data.due_date_offset)
    setTimeout(() => this.changeDetector.detectChanges(), 0);
  }

  ngOnDestroy() {

  }

  updateItem() {
    let ret = {due_date_type: this.dueDateType.value, due_date_offset: this.dueDateOffset.value}
    this.data.updateItem(ret);
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
