<div class="modal-header">
  <div class="modal-title h5">Find Video/Image/Form Asset</div>
</div>
<div class="modal-body" style="display: flex; flex-direction: column; padding-bottom: 12px">
    <div style="display: flex; flex: 0;">
      <mat-form-field style="display: flex; flex: 1">
        <mat-label>Search for Asset</mat-label>
        <input matInput style="width: 100%;" (input)="searchItems($event.target.value)" type="text" id="search_field"
               title="Enter the name, tag, or description of what you are trying to find." placeholder="search for assets..." autofocus>
      </mat-form-field>
    </div>
    <div class="h6">Results</div>
    <div style="overflow-y: auto; display: flex; flex: 1; flex-direction: column; gap: 10px; padding-bottom: 12px">
      <app-pal-list-item *ngFor="let lib of (libraryItems | async)"
                         [style.background-color]="lib.highlights ? lib.highlights.bgcolor : 'transparent'"
                         [text]="lib | findAssetName "
                         [buttons]="'play'"
                         [id]="lib.type + '_' + lib.id"
                         [play_popup]="'Click to preview this item.'"
                         [selectable]="true"
                         (click)="selectItem(lib)"
                         [selected]="lib === currentItem"
                         (buttonEvent)="buttonClick($event)"
      >
      </app-pal-list-item>


    </div>
</div>
<div class="modal-footer" style="display: flex; flex-direction: row; justify-content: space-between;">
  <button mat-raised-button color="primary" (click)="hideModal()"><mat-icon>cancel</mat-icon> Cancel</button>
  <button mat-raised-button color="accent" (click)="confirmItem()"><mat-icon>check</mat-icon> Select</button>
</div>
