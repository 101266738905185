<div style="display: flex; flex-direction: column; gap: 15px; height: 100%;" class="sidenav-container">
  <mat-sidenav-container>
    <mat-sidenav #sidenav [mode]="(media.isActive('sm') || media.isActive('xs')) ? 'over' : 'side'"
                 [opened]="!media.isActive('sm') && !media.isActive('xs')" style="display: flex; flex-direction: column;">
      <div id="logo">
        <img src="assets/pal_logo.png" style="height: 30px; width: 120px;"  routerLink='/profile/dashboard'  />
      </div>
      <mat-nav-list style="display: flex; flex-direction: column; flex: 1 1 100%;" >
        <div style="display: flex; flex: 1">
          <div *ngIf="organizations?.length > 0" class="organization-list">
            <mat-list-item *ngIf="(currentOrg | async)">
              <button mat-raised-button color="primary" class="ml-1" routerLink='/organization/{{(currentOrg | async).id}}/select_assign'>Assign</button>
            </mat-list-item>

            <mat-list-item style="height: 16px" >
              <div class="org-area-divider"></div>
            </mat-list-item>
            <mat-list-item>
              <div class="ml-1 mat-body-1 ">Organizations</div>
            </mat-list-item>
            <div class="org-link" *ngFor="let o of organizations">
              <mat-list-item (mouseout)="$event.stopPropagation()" (mouseleave)="$event.stopPropagation()" [ngClass]="o.id === (currentOrg | async)?.id ? 'selected org-item' : 'org-item'" routerLinkActive="active" routerLink="/organization/{{o.id}}/dashboard">
                <div style="max-width: 90%" class="ml-2">{{o.name}}</div>
                <mat-icon class="org-expander" *ngIf="o.id === (currentOrg | async)?.id">expand_less</mat-icon>
                <mat-icon class="org-expander" *ngIf="o.id !== (currentOrg | async)?.id">expand_more</mat-icon>
              </mat-list-item>
              <div *ngIf="(currentOrg | async)" [class]="o.id === (currentOrg | async)?.id ? '' : 'hideOrgInactive'"  routerLinkActive="orgActive" class="org-options">
                <mat-list-item class="mat-body-1" style="height: 40px;" *ngIf="false"  ><a class="ml-3 " routerLink="/organization/{{o.id}}/dashboard">Dashboard</a></mat-list-item>
                <mat-list-item *ngIf="!(currentOrg | async)?.is_medical_facility && (permissionService.permissions$ | async).includes('Patients/Members')" routerLink="/organization/{{o.id}}/members" class="mat-body-1" style="height: 40px;"><img src="/assets/icons/person.svg" style="width: 18px; height: 18px;" > <a  class="ml-3 "  >Members</a></mat-list-item>
                <mat-list-item *ngIf="(currentOrg | async)?.is_medical_facility && (permissionService.permissions$ | async).includes('Patients/Members')" routerLink="/organization/{{o.id}}/patients" class="mat-body-1" style="height: 40px;"> <img src="/assets/icons/person.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Patients</a></mat-list-item>
                <mat-list-item routerLink="/organization/{{o.id}}/groups" class="mat-body-1" style="height: 40px;" *ngIf="!(currentOrg | async)?.is_medical_facility" > <img src="/assets/icons/group.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Groups</a></mat-list-item>

                <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Library')" routerLink="/organization/{{o.id}}/videos" class="mat-body-1" style="height: 40px;" ><img src="/assets/icons/movie.svg" style="width: 18px; height: 18px;"><a class="ml-3 "  >Videos</a></mat-list-item>
                <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Library')" routerLink="/organization/{{o.id}}/forms" class="mat-body-1" style="height: 40px;" > <img src="/assets/icons/form.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Entry Forms</a></mat-list-item>
                <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Templates')" routerLink="/organization/{{o.id}}/templates" class="mat-body-1" style="height: 40px;" > <img src="/assets/icons/templates.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Templates</a></mat-list-item>
                <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Protocols')" routerLink="/organization/{{o.id}}/protocols" class="mat-body-1" style="height: 40px;"  > <img src="/assets/icons/protocols.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Protocols</a></mat-list-item>
                <mat-list-item
                  *ngIf="(permissionService.permissions$ | async).includes('Instruction Sets') && (currentOrg | async).feature_flags?.postop_orders"
                  routerLink="/organization/{{o.id}}/instruction_sets" class="mat-body-1" style="height: 40px;"><img
                  src="/assets/icons/InstructionSet.svg" style="width: 18px; height: 18px;"><a class="ml-3 ">Instruction
                  Sets</a></mat-list-item>
                <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Managers')" routerLink="/organization/{{o.id}}/managers" class="mat-body-1" style="height: 40px;"  > <img src="/assets/icons/managers.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Managers</a></mat-list-item>
                <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Reports')" routerLink="/organization/{{o.id}}/reports" class="mat-body-1" style="height: 40px;"  > <img src="/assets/icons/reports.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Reports</a></mat-list-item>
                <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Setup')" routerLink="/organization/{{o.id}}/setup" class="mat-body-1" style="height: 40px;"  > <img src="/assets/icons/settings.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Setup</a></mat-list-item>
                <div *ngIf="(permissionService.permissions$ | async).includes('Setup') && currentModule=='setup'" style="margin-left: 2em;">
                  <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Task Category Setup')" routerLink="/organization/{{o.id}}/setup/task_categories" class="mat-body-1" style="height: 40px;"  > <img src="/assets/icons/settings.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Task Categories</a></mat-list-item>
                  <mat-list-item *ngIf="(permissionService.permissions$ | async).includes('Provider Setup') && (currentOrg | async).feature_flags?.postop_orders " routerLink="/organization/{{o.id}}/providers_departments" class="mat-body-1" style="height: 40px;"  > <img src="/assets/icons/settings.svg" style="width: 18px; height: 18px;"><a  class="ml-3 "  >Providers</a></mat-list-item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-bottom">
          <div class="navbar-bottom-option" *ngIf="isAdminUser">
            <mat-list-item class="mat-list-item mat-focus-indicator" style="height: 40px;" routerLink="/admin/auth">
              <mat-icon style="color: grey; width: 28px; height: 28px; margin-right: 20px;">settings_applications</mat-icon> <a class="m1">Administration</a>
            </mat-list-item>
          </div>
          <div class="navbar-bottom-option">
            <mat-list-item class="mat-list-item mat-focus-indicator" style="height: 40px;" routerLink="/profile/edit">
              <img src="/assets/icons/person.svg" style="width: 18px; height: 18px;" ><a class="m1">My Profile</a>
            </mat-list-item>
          </div>
          <div class="navbar-bottom-option">
            <mat-list-item style="height: 40px;" (click)="logout()" >
              <img src="/assets/icons/logout.svg" style="width: 18px; height: 18px;"><a class="ml-1">Logout</a>
            </mat-list-item>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content style="height: 100vh; overflow: hidden; display: flex; flex-direction: column; " >
      <mat-toolbar color="primary" style="display: flex; flex-direction: row; justify-content: space-between; width: 100%">
        <div style="display: flex; flex: 0;">
        <button mat-icon-button  *ngIf="(media.isActive('sm') || media.isActive('xs'))" aria-hidden="false"
                aria-label="sidebar toggle button" (click)="sidenav.toggle()">
           <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        </div>
        <div class="h2" style="display: flex; flex: 1; padding-top: 12px">{{title}}</div>
        <div style="display: flex; flex-direction: row; flex: 0">
          <div *ngIf="showDelete" class="cancel-area"><button id="page_action_delete_button" style="border-color: white;" mat-stroked-button (click)="emitDelete()" ><mat-icon>delete</mat-icon>  Delete</button></div>
          <div *ngIf="showSave" class="cancel-area"><button   id="page_action_save_button" style="border-color: white;" mat-stroked-button (click)="emitSave()" ><mat-icon>save</mat-icon>  Save</button></div>
          <div *ngIf="showCancel" class="cancel-area"><button id="page_action_cancel_button" style="border-color: white;" mat-stroked-button (click)="goBack()" ><mat-icon>close</mat-icon>  Cancel</button></div>
        </div>
      </mat-toolbar>
      <div style="display: flex; flex-direction: column; overflow-y: auto; overflow-x: hidden; flex: 1 1 auto">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin" [fullScreen]="true"><p style="color: white; font-size: 2em"> Uploading Data... </p></ngx-spinner>
