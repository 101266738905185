import {Injectable, isDevMode} from '@angular/core';
import {ApiService} from '@services/api.service';
import {first} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public LoggedIn: boolean = false;

  constructor(private api: ApiService, private cookieService : CookieService) { }

  public setSession(authResult) {
    // TODO: remove
  }

  logout() {
    return new Promise<void>(async (resolve,reject) => {
      this.LoggedIn = false;
      localStorage.removeItem("token");
      this.api.logout().subscribe(() => {
        this.cookieService.delete("palaestra-session-cookie", "/");
        this.cookieService.delete("palaestra-admin-session-cookie", "/admin");
        resolve();
      });

    })

  }

  public verifyToken(token, payload) {
    return new Promise((resolve, reject) => {

      //TODO: remove.

      setTimeout(function() {
        resolve(token)
      });
    })

  }

}
