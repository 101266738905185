import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';

export type ResendTaskDialogData = Omit<ModalData, "onResponse" | "pre"> & {
  /**
   * @description Callback function when the user has explicitly clicked a button, it doesn't execute if the user dismiss the modal clicking outside
   */
  onResponse?: ({ dialog, payload }: { dialog: false | undefined; payload?: never; } | { dialog: true; payload?: string[] | string }) => any;
  notifyClient?: () => any;
  notifySecondaries?: (secondary: string[]) => any;
  showSecondaries?: boolean;
  showInput?: boolean;
  showCancel?: boolean;
}

@Component({
  selector: 'app-resend-task-dialog',
  templateUrl: "./resend-task-dialog.component.html",
  styleUrls: ['./resend-task-dialog.component.scss']
})
export class ResendTaskDialogComponent {
  private dialog: boolean = undefined;
  inputValue: string;
  secondary: string[] = []

  /**
   * @default {data.showCancel: false}
   * @default {data.showInput: false}
   * @default {data.showSecondaries: false}
   */
  constructor(
    public dialogRef: MatDialogRef<ResendTaskDialogComponent, ResendTaskDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: ResendTaskDialogData,
  ) {
    dialogRef.disableClose ??= true;
    data.showSecondaries ??= false;
    data.showInput ??= false;
    data.showCancel ??= false;
    if (this.data.onClose) dialogRef.afterClosed().subscribe(() => this.data.onClose({ dialog: this.dialog }))
  }

  confirm() {
    this.dialog = true;
    this.dialogRef.close();
    if (this.data.onResponse) {
      if(this.data.showSecondaries) {
        this.data.onResponse({ dialog: this.dialog, payload: this.secondary });
      }
      else if (this.data.showInput) {
        this.data.onResponse({ dialog: this.dialog, payload: this.inputValue })
      }
      else {
        this.data.onResponse({ dialog: this.dialog });
      }
    }
  }

  send(onlySecondaries: boolean) {
    if(onlySecondaries && this.data.notifySecondaries){
      this.data.notifySecondaries(this.secondary)
    }
    else if(!onlySecondaries && this.data.notifyClient){
      this.data.notifyClient();
    }
    this.dialogRef.close();
  }

  cancel() {
    this.dialog = false;
    this.data.onResponse?.({ dialog: this.dialog });
    this.dialogRef.close();
  }
}
