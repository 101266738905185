<h5 mat-dialog-title>{{previewVideo.title}}</h5>
<div mat-dialog-content>
<vg-player (onPlayerReady)="onPlayerReady($event)">
  <vg-overlay-play></vg-overlay-play>
  <vg-buffering></vg-buffering>

  <vg-controls>
    <vg-play-pause></vg-play-pause>

    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>

    <vg-fullscreen></vg-fullscreen>
  </vg-controls>
  <video #media
         [vgMedia]="media"
         [vgHls]="previewVideo.src"
         id="singleVideo"
         crossorigin >

  </video>
</vg-player>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: center; align-items: center">
  <button mat-raised-button color="accent" (click)="closeModal()">Close</button>
</div>
