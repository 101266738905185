export interface PalUser {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  display_name: string;
  email: string;
  phone_number: string;
  phone_type?: string;
  comm_email: boolean;
  comm_text: boolean;
  is_trainer: boolean;
  new_password?: string;
  password_confirmation?: string;
  permissions?: {
    id: string;
    name: string;
  }[];
}

export type PalTrainer = PalUser & {
  permissions: {
    id: string;
    name: string;
  };
};

export const blankUser: PalUser = {
  id: null,
  first_name: '',
  middle_name: '',
  last_name: '',
  display_name: '',
  phone_number: '',
  phone_type: null,
  email: '',
  comm_email: false,
  comm_text: false,
  is_trainer: false,
};
