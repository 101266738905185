import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  newPasswordForm: UntypedFormGroup;
  submitted: boolean = false;
  key: string;
  user_id: bigint;
  constructor(private modalService: ModalService, private apiService: ApiService, private ar: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.newPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.mustMatch('password', 'confirmPassword')
    });

    this.ar.params.subscribe(params => {
      this.key = params.token;
      this.user_id = params.uid;
      if(!this.key || !this.user_id)
        return this.router.navigate(['/']);

      this.apiService.verifyPasswordResetKey(this.key, this.user_id).subscribe({
        next: (res) => {
        },
        error: () => {
            this.router.navigate(['/login']);
        }
    });
    });
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.newPasswordForm.invalid) {
      return;
    }

    this.apiService.resetPasswordWithKey(this.key, this.newPasswordForm.controls.password.value, this.newPasswordForm.controls.confirmPassword.value, this.user_id).subscribe(x => {
      this.modalService.showMessageDialog({
        data: {
          prompt: 'Your password has been reset.  Please log in with your new password.',
          onClose() {
            this.router.navigate(['/login']);
          }
        }
      });
    });

  }

}
