import {Component, Inject, ViewChild} from '@angular/core';
import {
  VgHlsDirective} from '@videogular/ngx-videogular/streaming';
import {VgApiService} from '@videogular/ngx-videogular/core';
import { ModalData } from '@app/services/modal.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Media {
  title: string;
  src: string;
  thumbnail: string;
}

export type VideoPreviewModalData = Pick<ModalData, "onClose"> & { previewVideo?: Media };

@Component({
  selector: 'app-video-preview-dialog',
  styleUrls: ['video-preview-dialog.component.scss'],
  templateUrl: './video-preview-dialog.component.html',
})

export class VideoPreviewDialogComponent {
  private dialog: boolean = undefined;
  @ViewChild(VgHlsDirective, { static: false }) vgHls: VgHlsDirective;

  previewVideo: Media;
  api: VgApiService;

  constructor(
    private dialogRef: MatDialogRef<VideoPreviewDialogComponent, VideoPreviewModalData>,
    @Inject(MAT_DIALOG_DATA) public data: VideoPreviewModalData
  ) {
    dialogRef.disableClose ??= true;
    this.previewVideo = data.previewVideo;
    if (this.data.onClose) this.dialogRef.afterClosed().subscribe(() => { this.data.onClose({ dialog: this.dialog }); });
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
  }


  closeModal() {
    this.api.getDefaultMedia()?.pause();
    this.dialog = false;
    this.dialogRef.close();
  }
}
