<div class="item" [ngClass]="_selected ? 'selected' : ''"
     [style.background-color]="highlights ? highlights.bg : 'transparent'">
  <mat-icon class="first-icon" *ngIf="(highlights?.type_icon)">{{highlights.type_icon}}</mat-icon>
  <mat-icon class="first-icon" *ngIf="(highlights?.icon)">{{highlights.icon}}</mat-icon>
  <mat-icon class="first-icon" [title]="icon_title" *ngIf="icon">{{icon}}</mat-icon>
  <div style="display: flex; flex: 1; margin-top: 12px" class="h6">{{text}}</div>
  <div style="display: flex; flex: 0;"  class="show-on-hover buttons">
    <button *ngIf="config_buttons.includes('play')" title="{{this.play_popup}}" (click)="action($event, 'play')" mat-flat-button class="preview-btn action-button"><mat-icon>play_arrow</mat-icon><div class="mat-caption">Preview</div></button>&nbsp;
    <button *ngIf="config_buttons.includes('edit')" title="{{this.edit_popup}}" (click)="action($event, 'edit')" mat-flat-button class="edit-btn action-button"><mat-icon>edit</mat-icon><div class="mat-caption">Edit</div></button>&nbsp;
    <button *ngIf="config_buttons.includes('delete')" title="{{this.delete_popup}}" (click)="action($event, 'delete')" mat-flat-button class="delete-btn action-button"><mat-icon>delete</mat-icon><div class="mat-caption">Delete</div></button>&nbsp;
    <button *ngIf="config_buttons.includes('add')" title="{{this.add_popup}}" (click)="action($event, 'add')" mat-flat-button class="add-btn action-button"><mat-icon>add</mat-icon><div class="mat-caption">Add</div></button>&nbsp;
    <button *ngIf="config_buttons.includes('remove')" title="{{this.remove_popup}}" (click)="action($event, 'remove')" mat-flat-button class="remove-btn action-button"><mat-icon>remove</mat-icon><div class="mat-caption">Remove</div></button>&nbsp;
  </div>
</div>
