
<h5 mat-dialog-title>Instruction Set Builder - Edit Section Properties</h5>
<mat-dialog-content style="display: flex; flex-direction: column; height: 100%;">
  <mat-form-field>
    <mat-label>Name:</mat-label>
    <input matInput [(ngModel)]="section.name" [formControl]="nameField" autofocus>
    <mat-error *ngIf="nameField.hasError('required')">
      Section name is required.
    </mat-error>
  </mat-form-field>
  <div class="section-title" >
    <div class="h5 section-title-text">Section Items</div>
    <button mat-flat-button class="add action-button" (click)="addItem()"
            title="Click to add a new item."><mat-icon>add</mat-icon><div class="mat-caption">Item</div></button>
  </div>

  <div cdkDropListGroup style="overflow-y: auto;" class="drag-instruction-container">
    <div
      cdkDropList
      [cdkDropListData]="(items | async)"
      (cdkDropListDropped)="drop($event)"
      class="drag-instruction-list"
    >
      <app-pal-list-item 
        *ngFor="let p of (items | async)"
        cdkDrag
        class="drag-instruction-item"
        [text]="p.name"
        [buttons]="p.asset_id ? 'play,edit,delete' : 'edit,delete'"
        [id]="p.id"
        [play_popup]="'Click to preview this item.'"
        [edit_popup]="'Click to edit this item.'"
        [delete_popup]="'Click to remove this item.'"
        (buttonEvent)="buttonEvent($event)"
      ></app-pal-list-item>
    </div>
    <div [class]="itemStatus" *ngIf="(items | async)?.length === 0">No items added yet.  Click the plus to add.</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; flex-direction: row; justify-content: space-between" >
  <button mat-raised-button color="default" (click)="hidePopup()"><mat-icon>cancel</mat-icon> Cancel</button>
  <button mat-raised-button color="accent" (click)="updateSection()"><mat-icon>update</mat-icon> Update</button>
</mat-dialog-actions>
