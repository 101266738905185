import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ApiService} from '@services/api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {liveSearch} from '@shared/component/livesearch/livesearch';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';
import { PalOrganization } from '@app/core/models/pal-organization';

export type FindOrganizationDialogData = Pick<ModalData, "onClose">

@Component({
  selector: 'app-find-organizations-dialog',
  templateUrl: './find-organizations-dialog.component.html',
  styleUrls: ['./find-organizations-dialog.component.scss']
})
export class FindOrganizationsDialogComponent implements OnInit {
  private dialog: boolean = undefined;

  private searchSubject = new BehaviorSubject<string>('');
  organizations: Observable<PalOrganization[]>;
  selectedOrganization: any;

  @Output() onSelect = new EventEmitter<{id: number, name: string}>();

  constructor(private apiService: ApiService,
  private dialogRef: MatDialogRef<FindOrganizationsDialogComponent, FindOrganizationDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: FindOrganizationDialogData
    ) { 
      dialogRef.disableClose ??= true;
      if (this.data.onClose) dialogRef.afterClosed().subscribe(() => this.data.onClose({ dialog: this.dialog }))
    }

  ngOnInit(): void {
    this.organizations = this.searchSubject.pipe(
      liveSearch(query => this.apiService.adminSearchMedicalFacilities(query)) // You should implement searchByName method in organizationService
    );
  }

  searchOrganizations(name: string): void {
    this.searchSubject.next(name);
  }

  markOrganization(organization: any): void {
    this.selectedOrganization = organization;
  }

  selectOrganization(): void {
    this.onSelect.emit({id: this.selectedOrganization.id, name: this.selectedOrganization.name});
    this.hideModal();
  }

  hideModal() {
    this.dialog = false;
    this.dialogRef.close();
  }

}
