import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findAssetName'
})
export class FindAssetNamePipe implements PipeTransform {

  transform(value: any): string {
    let ret = value.name;
    if(value.highlights && value.highlights.icon)
      ret += ' <i class=\"' + value.highlights.icon + '\"></i>';
    return ret;
  }

}
