import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pal-list-item',
  templateUrl: './pal-list-item.component.html',
  styleUrls: ['./pal-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalListItemComponent implements OnInit {

  @Input() buttons: string = ""; // CSV. edit, delete, play, add, remove are valid values
  @Input() id: number;
  @Input() text: string;
  @Input() play_popup: string = "";
  @Input() edit_popup: string = "";
  @Input() delete_popup: string = "";
  @Input() add_popup: string = "";
  @Input() remove_popup: string = "";
  @Input() selectable: boolean = false;
  @Input() highlights: any;
  @Input() icon: string = "";
  @Input() icon_title: string = "";
  _selected: boolean = false;
  @Input() set selected(s) {
    this._selected = s;
  }
  @Output() buttonEvent: EventEmitter<any> = new EventEmitter();
  config_buttons: string[];

  constructor() { }

  ngOnInit(): void {
    this.config_buttons = this.buttons.split(',');
  }

  action($event, action) {
    this.buttonEvent.emit({event: action, data: this.id});
    $event.stopPropagation();
  }
}
