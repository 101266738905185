import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {MediaObserver} from '@angular/flex-layout';
import {PageService} from '@services/page.service';
import {MatSidenav} from '@angular/material/sidenav';
import {Breakpoints} from '@angular/cdk/layout';
import {AuthService} from '@services/auth.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

import {NgxSpinnerService} from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminLayoutComponent implements AfterViewInit, OnDestroy {

  title: string;
  currentModule: string = "";
  mode: string = 'side';
  opened: boolean = true;
  subs: Subscription [] = [];
  modals: number = 0;
  org: any = null;
  showCancel: boolean = false;
  showDelete: boolean = false;
  showSave: boolean = false;
  spinnerTitle: string = "";

  extraActions: PageService['extraActions'];


  constructor(private location: Location, private router: Router, private auth: AuthService, private ref: ChangeDetectorRef, private sidenav: MatSidenav,
              public media: MediaObserver, private pageService: PageService,
              private spinner: NgxSpinnerService, private cookieService: CookieService) {
    this.extraActions = pageService.extraActions;
    this.subs.push(this.pageService.subject.subscribe((newTitle: string) => {
      this.title = newTitle;
    }));
    this.subs.push(this.router.events.subscribe((ev) => {
      this.showCancel = false;
      this.showSave = false;
      this.showDelete = false;
    }));
    this.subs.push(this.pageService.showCancel.subscribe(() => {
      this.showCancel = true;
    }));
    this.subs.push(this.pageService.showDelete.subscribe(() => {
      this.showDelete = true;
    }));
    this.subs.push(this.pageService.showSave.subscribe(() => {
      this.showSave = true;
    }));
    this.subs.push(this.pageService.showSpinnerOverlay.subscribe((title : string) => {
      this.spinnerTitle = title;
      this.spinner.show();
    }));
    this.subs.push(this.pageService.hideSpinnerOverlay.subscribe(() => {
      this.spinnerTitle = "";
      this.spinner.hide();
    }));
    this.subs.push(this.pageService.hideButtons.subscribe(() => {
      this.showSave = false;
      this.showCancel = false;
      this.showDelete = false;
    }));
  }

  async logout() {
    await this.auth.logout();
    this.router.navigate(['/']);
  }

  ngAfterViewInit(): void {

    window.dispatchEvent(new Event('resize'));
    if (!this.media.isActive(Breakpoints.Handset) ) {
      this.opened = true;
      this.sidenav.mode = this.mode = 'side';
      this.sidenav.open();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  goBack() {
    this.location.back();
  }

  emitSave() {
    this.pageService.saveEvent.next('');
  }

  emitDelete() {
    this.pageService.deleteEvent.next('');
  }

  leaveAdmin() {
    this.cookieService.delete('palaestra-admin-session-cookie');
    this.router.navigate(['/profile']);
  }
}
