import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';

export type FormPreviewModalData = Pick<ModalData, "onClose"> & { form: any }

@Component({
  selector: 'app-form-preview-dialog',
  templateUrl: './form-preview-dialog.component.html',
})
export class FormPreviewDialogComponent {
  private dialog: boolean = undefined;

  constructor(
    private dialogRef: MatDialogRef<FormPreviewDialogComponent, FormPreviewModalData>,
    @Inject(MAT_DIALOG_DATA) public data: FormPreviewModalData
  ) {
    dialogRef.disableClose ??= true;
    if (this.data.onClose) this.dialogRef.afterClosed().subscribe(() => { this.data.onClose({ dialog: this.dialog }); });
  }

  closeModal() {
    this.dialog = false;
    setTimeout(() => { this.dialogRef.close(); }, 2);
  }
}
