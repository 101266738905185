import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InstructionSet, Provider } from '@app/core/models/pal-instruction-set';
import { ModalData } from '@app/services/modal.service';
import * as _ from 'lodash';
import {map, Observable, of} from 'rxjs';

export type AssignToProviderModalData = Pick<ModalData, "onClose"> & {
  setProviders: (providers: any, all_providers: any) => any,
  selectedInstructionSet: InstructionSet;
  providers: Provider[];
}

@Component({
  selector: 'app-assign-to-provider-dialog',
  templateUrl: './assign-to-provider-dialog.component.html',
  styleUrls: ['./assign-to-provider-dialog.component.scss']
})
export class AssignToProviderDialogComponent {
  foundProviders: Observable<Provider[]>;
  selectedProviders: Observable<Provider[]>;
  allProviders: boolean = false;

  constructor(
      private dialogRef: MatDialogRef<AssignToProviderDialogComponent, AssignToProviderModalData>,
      @Inject(MAT_DIALOG_DATA) public data: AssignToProviderModalData
    ) {
    dialogRef.disableClose ??= true;

    this.foundProviders = of(_.cloneDeep(this.data.providers));
    this.allProviders = this.data.selectedInstructionSet.all_providers; // this.data.providers.every(({ id }) => this.data.selectedInstructionSet.Providers.some((p) => p.id === id));
    this.selectedProviders = of(_.cloneDeep(this.allProviders ? this.data.providers : this.data.selectedInstructionSet.Providers));
  }

  selectAllProviders() {
    if (this.allProviders) this.selectedProviders = of(_.cloneDeep(this.data.providers));
  }

  searchItems(needle: string) {
    this.foundProviders = of(this.data.providers.filter(x => x.name.toUpperCase().includes(needle.toUpperCase())));
  }

  addButtonClick(evt) {
    let p = this.data.providers.find(pr => pr.id == evt.data);
    if(p) {
      this.selectedProviders = this.selectedProviders.pipe(
        map(providers => {
          let i = providers.find(pr => pr.id == p.id);
          if(!i) {
            return [...providers, p].sort((a, b) => a.name > b.name ? 1 : -1);
          }
          return providers;
        })
      );
    }
  }

  removeButtonClick(evt) {
    this.allProviders = false;
    this.selectedProviders = this.selectedProviders.pipe(
      map(providers => {
        let index = providers.findIndex(pr => pr.id == evt.data);
        if(index >= 0) {
          let newProviders = [...providers];
          newProviders.splice(index, 1);
          return newProviders.sort((a, b) => a.name > b.name ? 1 : -1);
        }
        return providers;
      })
    );
  }

  update() {
    const sub = this.selectedProviders.subscribe(selectedProviders => {
      this.data.setProviders(selectedProviders, this.allProviders);
      this.dialogRef.close();
      sub.unsubscribe();
    });
  }

  hidePopup() {
    this.dialogRef.close();
  }
}
