import {Component, Inject} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModalData } from '@app/services/modal.service';

@Component({
  selector: 'app-error-dialog',
  templateUrl: "./error-dialog.component.html",
  styles: ["pre {text-align: left; font-family: var(--bs-body-font-family); font-size: 16px;}"],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
})
export class ErrorDialogComponent {
  private dialog: boolean = undefined;
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
    if (this.data.onClose) dialogRef.afterClosed().subscribe(() => this.data.onClose({ dialog: this.dialog }))
  }

  closeDialog() {
    this.dialog = true;
    this.dialogRef.close();
    this.data.onResponse?.({ dialog: this.dialog });
  }
}
