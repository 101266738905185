import {Component, Inject} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModalQuestionData } from '@app/services/modal.service';

@Component({
  selector: 'app-question-dialog',
  templateUrl: "./question-dialog.component.html",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
})
export class QuestionDialogComponent {
  private dialog: boolean = undefined;
  constructor(
    public dialogRef: MatDialogRef<QuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalQuestionData,
  ) {
    if (this.data.onClose) dialogRef.afterClosed().subscribe(() => this.data.onClose({ dialog: this.dialog }))
  }

  closeDialog(res: boolean) {
    this.dialog = res;
    this.dialogRef.close();
    this.data.onResponse?.({ dialog: this.dialog });
  }
}
