<div class="landing-wrapper">
 <div class="left-column">
   <div class="main-logo">
     <img src="assets/pal_logo.png" />
   </div>

   <div class="landing-image-1">
     <div class="landing-img-div">
      <img class="img-gradient" src="assets/landing_img1.png" height="570" width="570"/>
     </div>
   </div>
 </div>
<div class="right-column">
  <div class="header-text">
    <div class="font1">
      Better patient experiences
    </div>
    <div class="font2">
      start here.
    </div>
  </div>
  <div class="login-area">
    <login-form-component #login></login-form-component>
  </div>
</div>

</div>

