<div style="margin-top: 2em;">
  <mat-label>Do you want to send this to others? *</mat-label>
  <mat-form-field>
    <mat-label style="color: black">Enter an email or phone number, then press tab or enter to add to the list.</mat-label>
    <mat-chip-list #secondaryList aria-label="Enter email or phone number" title="Enter an email or phone number, then press tab or enter to add.">
      <mat-chip *ngFor="let s of secondaryContacts"
                (removed)="removeSecondary(s)">
        {{s}}
        <button matChipRemove [attr.aria-label]="'remove ' + s">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input placeholder=""
             [matChipInputFor]="secondaryList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"

             (matChipInputTokenEnd)="addSecondary($event)"/>
    </mat-chip-list>
  </mat-form-field>
</div>
