import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest,
  HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import {PageService} from '@services/page.service';
import { ModalData, ModalService } from './modal.service';
import { MessageDialogComponent } from '@app/shared/component/Dialog/message-dialog/message-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import {UserService} from '@services/user.service';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  modalOnPipeRef: MatDialogRef<MessageDialogComponent, ModalData>;
  constructor(private userService: UserService, private modalService: ModalService, private router: Router, public pageService: PageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>>{
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.userService.unsavedInfo = false;
          if(error.status >= 400 && error.status != 401 && error.status != 403) {
            this.pageService.hideSpinner();
            this.modalService.showErrorDialog({
              data: {
                prompt: error.error ? error.error.error : error.status + ' ' + error.statusText,
              },
            });
            return throwError(() => new Error(error.message));
          } else if(error.status == 401 || error.message == "User Session Expired") {
            if(error.url.includes("/authenticate")) {
              return throwError(() => new Error("Invalid email/phone number or password."));
            } else {
              this.pageService.hideSpinner();

              if(!this.modalOnPipeRef) {
                this.modalOnPipeRef = this.modalService.showMessageDialog({
                  data: {
                    prompt: "Your session has expired, please log in again.",
                    onClose: () => { this.modalOnPipeRef = null; }
                  }
                });
              }
              this.router.navigate(['/login']);
            }
          } else if (error.status == 403) {
            if(error.url.includes("/organization")) {
              this.pageService.showErrorMessage('You do not have access to that.', 'Ok');
              this.router.navigate(['/profile']);
            } else if (error.url.includes("/admin")) {
              this.pageService.showErrorMessage('You do not have access to that.', 'Ok');
              this.router.navigate(['/profile']);
            } else {
              this.pageService.showErrorMessage('You do not have access to that.', 'Ok');
              this.router.navigate(['/profile']);
            }
          }

        })
      )
  };
}
