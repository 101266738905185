import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {environment} from '@env';

export type SurveyOverPhoneModalData = Pick<ModalData, "onClose"> & {
  task_id: string;
}

@Component({
  selector: 'app-survey-over-phone-dialog',
  templateUrl: "./survey-over-phone-dialog.component.html",
  styles: ["pre {text-align: left; font-family: var(--bs-body-font-family); font-size: 16px;}"],
})
export class SurveyOverPhoneDialogComponent {
  private dialog: boolean = undefined;
  public urlSafe: SafeResourceUrl;
  constructor(
    public dialogRef: MatDialogRef<SurveyOverPhoneDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: SurveyOverPhoneModalData,
  ) {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.frontEndUrl}/task/${data.task_id}?otp=true`);
    if (this.data.onClose) dialogRef.afterClosed().subscribe(() => this.data.onClose({ dialog: this.dialog }))
  }

  closeDialog() {
    this.dialog = true;
    this.dialogRef.close();
  }

}
