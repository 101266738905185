import {
  ChangeDetectorRef,
  Component,
  ComponentFactory, ComponentFactoryResolver,
  EventEmitter, Inject, Input, OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {UserService} from '@services/user.service';
import {blankUser, PalUser} from '@core/models/pal-user';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {UserInformationComponent} from '@shared/component/user-information/user-information.component';
import {PageService} from '@services/page.service';
import { ModalData, ModalQuestionData, ModalService } from '@app/services/modal.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QuestionDialogComponent } from '@app/shared/component/Dialog/question-dialog/question-dialog.component';

@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {

  user: PalUser;
  profile : PalUser;
  profiles: Observable<any[]>;
  deleteRef : MatDialogRef<QuestionDialogComponent, ModalQuestionData>;
  showSave: boolean = false;
  subs: Subscription[] = [];
  modalRef: MatDialogRef<ProfileDialogComponent, ProfileDialogModalData>;

  @ViewChild('mainUser') mainUser: UserInformationComponent;

  constructor(private pageService: PageService, private userService: UserService, private router: Router, private modalService: ModalService,
              public dialog: MatDialog
    ) {  }

  ngOnInit(): void {
    this.userService.getUser().subscribe(x => {
      this.user = x['user'];
      this.mainUser.user = x['user'];
      this.subs.push(this.mainUser.changedEvent.subscribe(() => {
        this.userService.unsavedInfo = true;
      }));

    });
    this.profiles = this.userService.getProfiles();
    this.userService.setOrgModule('my-profile');
    this.pageService.changeHeaderTitle('My Profile');
    this.subs.push(this.pageService.saveEvent.subscribe(() => this.updateInfo()));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  updateInfo() {
    let u = this.mainUser.validateAndReturn();
    if(!u) return this.modalService.showMessageDialog({
        data: {
          prompt: "Please fix errors in the profile form."
        }
      });

    this.user = u;
    this.userService.updateUser(this.user).then((s) => {
      if(s == 'success') {
        this.userService.unsavedInfo = false;
        this.pageService.showSuccessMessage("Profile updated.");
        this.router.navigate(['/profile']);
      }
      else {
        this.pageService.showErrorMessage("Unable to save user information.", "OK");
      }
    })
  }

  profileClick(id) {
    this.profiles.subscribe(x => {
      if(id)
        this.profile = x.find(y => y.id === id);
      else
        this.profile = blankUser;

      this.modalRef = this.dialog.open<ProfileDialogComponent, ProfileDialogModalData>(ProfileDialogComponent, {
        data: {
          profile: this.profile,
        }
      });
      this.modalRef.componentInstance.saveEvent.subscribe((u) => {
        if (!u) return this.modalService.showMessageDialog({
          data: {
            prompt: "Please fix errors in profile."
          }
        });

        this.updateProfile(u);
        this.profile = null;
        setTimeout(() => { this.modalRef.close(); }, 2);
      })

    });
  }

  cancelCreateProfile() {
    setTimeout(this.modalRef.close, 2);
  }

  updateProfile(u) : void {
    this.userService.updateProfile(u.id, u).then((s) => {
      if(s != 'success') {
        this.modalService.showMessageDialog({
          data: {
            prompt: "Could not save new profile: " + s.error
          }
        });
      } else {
        this.profiles = this.userService.getProfiles();
      }
    })
  }

  confirmDelete(id) : void {
    if(this.deleteRef) return;
    this.deleteRef = this.modalService.showQuestionDialog({
      data: {
        prompt: 'Are you sure you want to delete this profile?',
        onResponse: ({ dialog }) => {
          if (dialog) this.deleteProfile(id)
          this.deleteRef.close();
          setTimeout(() => {this.deleteRef = null;}, 10);
        }
      }
    });
  }

  cancelDelete(): void {
    setTimeout(this.deleteRef.close, 2);
  }

  deleteProfile(id) : void {
    this.userService.deleteProfile(id).then(() => {
      setTimeout(this.deleteRef.close, 2);
      this.profiles = this.userService.getProfiles();
    }).catch(err => {
      this.modalService.showMessageDialog({
        data: {
          prompt: "Could not delete profile profile: " + err
        }
      });
    })
  }

}

export type ProfileDialogModalData = Pick<ModalData, "onClose"> & {
  profile: PalUser;
  show_trainer?: boolean;
};

@Component({
  selector: 'app-profile-content',
  template: `
    <div id="profile_details_dlg" style="max-width: 98%; margin: 0 auto">
      <div>
        <app-user-information #profi [user]="profile" [show_trainer]="show_trainer"></app-user-information>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between" >
        <button mat-button color="primary" [routerLink]="['/organization', orgId, 'member', profile.id]">Cancel</button>
        <button mat-raised-button color="accent" (click)="saveProfi()">{{save_text}}</button>
      </div>
    </div>

  `
})
export class ProfileDialogComponent {
  private dialog: boolean = undefined;
  @ViewChild("profi", {read: UserInformationComponent})
  public vc: UserInformationComponent;
  @Input()
  public orgId: number;
  @Input()
  public profile: PalUser;
  @Input()
  public show_trainer: boolean = false;
  @Input()
  save_text: string = "Save";
  patient_member: string = "Member";

  @Output()
  saveEvent: EventEmitter<PalUser> = new EventEmitter();

  constructor(
      private pageService: PageService, private userService: UserService,
      private dialogRef: MatDialogRef<ProfileDialogComponent, ProfileDialogModalData>,
      @Inject(MAT_DIALOG_DATA) public data: ProfileDialogModalData
    ) {
    dialogRef.disableClose ??= true;
    this.save_text ||= "Save";
    this.setTitle();
    this.profile = this.data.profile;
    this.show_trainer = this.data.show_trainer;
    if (this.data.onClose) this.dialogRef.afterClosed().subscribe(() => {
      this.data.onClose({ dialog: this.dialog });
    });
  }

  async setTitle() {
    let org = await this.userService.getCurrentOrgRecord();
    if(org.is_medical_facility)
      this.patient_member = "Patient";

    if(this.profile?.id && this.save_text == "Save") {
      this.save_text = "Save " + this.patient_member;
    } else if(!this.profile?.id && this.save_text == "Save") {
      this.save_text = "Create "+ this.patient_member;
    }
  }

  saveProfi() {
    this.dialog = true;
    let u = this.vc.validateAndReturn();
    this.saveEvent.emit(u);
  }
}
