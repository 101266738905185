<h5 mat-dialog-title>Instruction Set Builder - Assign To Providers</h5>
<mat-dialog-content style="height: 100%; display: flex; flex-direction: column;">
  <div class="section-area">
    <mat-checkbox [(ngModel)]="allProviders" (change)="selectAllProviders()" title="Check this box if you want all providers to be able to use this instruction set.">Assign to All Providers</mat-checkbox>
  </div>
  <div class="providers-area">
    <div style="display: flex; flex: 0.5 0.5 100%; flex-direction: column; margin-right: 8px">
      <div class="h6">Search Providers</div>
      <mat-form-field>
        <mat-label>Enter Provider Name To Search</mat-label>
        <input matInput (input)="searchItems($event.target.value)" title="Enter a provider's name to search and filter the list below.">
      </mat-form-field>
      <div style="display: flex; flex: 1; overflow-y: auto; flex-direction: column;">
        <app-pal-list-item *ngFor="let p of (foundProviders | async | filter:(selectedProviders | async))"
                           [text]="p.name"
                           [buttons]="'add'"
                           [id]="p.id"
                           [add_popup]="'Click to allow this provider to use the instruction set.'"
                           (buttonEvent)="addButtonClick($event)"
        ></app-pal-list-item>

      </div>
    </div>
    <div style="display: flex; flex: 0.5 0.5 100%; flex-direction: column; ">
      <div class="h6">Selected Providers</div>
      <div style="display: flex; flex: 1; overflow-y: auto; flex-direction: column;">
        <app-pal-list-item *ngFor="let p of (selectedProviders | async)"
                           [text]="p.name"
                           [buttons]="'remove'"
                           [id]="p.id"
                           [remove_popup]="'Click to remove provider access from this instruction set.'"
                           (buttonEvent)="removeButtonClick($event)"
        ></app-pal-list-item>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; flex-direction: row; justify-content: space-between" >
  <button mat-raised-button color="default" (click)="hidePopup()"><mat-icon>cancel</mat-icon> Cancel</button>
  <button mat-raised-button color="accent" (click)="update()"><mat-icon>update</mat-icon> Update</button>
</mat-dialog-actions>
