<h5 mat-dialog-title class="padded">{{this.previewName}}</h5>
<mat-dialog-content id="template_step_list_modal" class="padded">
  <div style="overflow-y: auto; display: flex; flex: 1; flex-direction: column; gap: 6px; margin-bottom: 6px; margin-left: 18px; margin-right: 18px">
    <app-pal-list-item *ngFor="let lib of theSteps"
                       [style.background-color]="lib.highlights ? lib.highlights.bgcolor : 'transparent'"
                       [text]="lib | findAssetName "
                       [buttons]="'play'"
                       [id]="lib.type + '_' + lib.iid"
                       [play_popup]="'Click to preview this asset.'"
                       (click)="preview(lib)"
                       (buttonEvent)="buttonClick($event)">
    </app-pal-list-item>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; flex-direction: row; justify-content: center">
  <button mat-raised-button color="default" (click)="close()"><mat-icon>close</mat-icon> Close</button>
</mat-dialog-actions>
