<div style="display: flex; flex-direction: column; gap: 15px; height: 100vh;" class="sidenav-container">
  <mat-sidenav-container>
    <mat-sidenav #sidenav [mode]="(media.isActive('sm') || media.isActive('xs')) ? 'over' : 'side'"
                 [opened]="!media.isActive('sm') && !media.isActive('xs')"
                 style="display: flex; flex-direction: column;">
      <div id="logo">
        <img src="assets/pal_logo.png" height="30" width="120" (click)="leaveAdmin()"/>
      </div>
      <mat-nav-list style="display: flex; flex-direction: column; flex: 1 1 100%;">
        <div style="display: flex; flex: 1; flex-direction: column;">
          <mat-list-item routerLink="/admin/organizations" class="mat-body-1" style="height: 40px;">
            <mat-icon>business</mat-icon>
            <a class="ml-3 ">Organizations</a></mat-list-item>
          <mat-list-item routerLink="/admin/managers" class="mat-body-1" style="height: 40px;">
            <mat-icon>assignment_ind</mat-icon>
            <a class="ml-3 ">Managers</a></mat-list-item>
          <mat-list-item routerLink="/admin/libraries" class="mat-body-1" style="height: 40px;">
            <mat-icon>library_books</mat-icon>
            <a class="ml-3 ">Asset Libraries/Licenses</a></mat-list-item>
          <mat-list-item routerLink="/admin/tasks" class="mat-body-1" style="height: 40px;">
            <mat-icon>assignment</mat-icon>
            <a class="ml-3 ">Tasks</a></mat-list-item>
          <mat-list-item routerLink="/admin/feature_flags" class="mat-body-1" style="height: 40px;">
            <mat-icon>flag</mat-icon>
            <a class="ml-3 ">Feature Flags</a></mat-list-item>
        </div>
        <div class="navbar-bottom">
          <div class="navbar-bottom-option">
            <mat-list-item class="mat-list-item mat-focus-indicator" style="height: 40px;" (click)="leaveAdmin()">
              <a class="m1">Leave Administration</a>
            </mat-list-item>
          </div>
          <div class="navbar-bottom-option">
            <mat-list-item style="height: 40px;" (click)="logout()">
              <img src="/assets/icons/logout.svg" style="width: 18px; height: 18px;"><a class="ml-1">Logout</a>
            </mat-list-item>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content style="display: flex; flex-direction: column; height: 100vh; overflow: hidden">
      <mat-toolbar color="primary">
        <div
          style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-top: 12px; margin-bottom: 12px;">
          <div>
            <button mat-icon-button *ngIf="(media.isActive('sm') || media.isActive('xs'))" aria-hidden="false"
                    aria-label="sidebar toggle button" (click)="sidenav.toggle()">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <h2>{{ title }}</h2>
          </div>
          <div style="display: flex; flex-direction: row; ">
            <div *ngFor="let action of extraActions" class="cancel-area">
              <button style="border-color: white;" mat-stroked-button (click)="action.callback()">
                <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
                {{ action.text }}
              </button>
            </div>
            <div *ngIf="showDelete" class="cancel-area">
              <button id="page_action_delete_button" style="border-color: white;" mat-stroked-button
                      (click)="emitDelete()">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </div>
            <div *ngIf="showSave" class="cancel-area">
              <button id="page_action_save_button" style="border-color: white;" mat-stroked-button (click)="emitSave()">
                <mat-icon>save</mat-icon>
                Save
              </button>
            </div>
            <div *ngIf="showCancel" class="cancel-area">
              <button id="page_action_cancel_button" style="border-color: white;" mat-stroked-button (click)="goBack()">
                <mat-icon>close</mat-icon>
                Cancel
              </button>
            </div>
          </div>
        </div>

      </mat-toolbar>
      <div style="display: flex; flex-direction: column; overflow-y: auto; overflow-x: hidden">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin" [fullScreen]="true"><p
  style="color: white; font-size: 2em"> Uploading Data... </p></ngx-spinner>
