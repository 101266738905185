
<div style="display: flex; flex: 1; overflow: hidden;">
  <div style="display: flex; flex-direction: column; flex: 1; align-items: center;">
    <div class="form-area" #scrollableArea (scroll)="checkScroll()" >
      <form style="height: 100%" [formGroup]="formGroup">
      <formly-form #formlyForm [model]="formValues" [fields]="fields" [options]="options"
                   [form]="formGroup"></formly-form>
      </form>
      <div [ngStyle]="{'display': canScrollDown ? 'block' : 'none'}" class="scroll-down-indicator">
        <button mat-flat-button color="action" class="action-button" (click)="scrollDown()"
                title="Show Next"><mat-icon>arrow_downward</mat-icon>
          <div class="mat-caption">More</div>
        </button>

      </div>
      <div [ngStyle]="{'display': canScrollUp ? 'block' : 'none'}" class="scroll-up-indicator">
        <button mat-flat-button color="action" class="action-button" (click)="scrollUp()"
                title="Show Previous"><mat-icon>arrow_upward</mat-icon>
          <div class="mat-caption">More</div>
        </button>
      </div>
    </div>
    <div style="display: flex; flex: 0; width: 100%; justify-content: center" *ngIf="!editing && !readOnly" >
      <button mat-raised-button color="primary" (click)="record()">Save and Next</button>
    </div>
  </div>
</div>


