import {
  Component,
  Inject,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';
import {PageService} from '@services/page.service';
import { Observable } from 'rxjs';

export type PreviewStepsModalData = Pick<ModalData, "onClose"> & {
    previewName: string;
    theSteps: any[];
    orgId: number;
}

@Component({
  selector: 'app-preview-steps-dialog',
  templateUrl: './preview-steps-dialog.component.html',
  styleUrls: ['./preview-steps-dialog.component.scss'],
})
export class PreviewStepsDialogComponent {
private dialog: boolean = undefined;
  @Input() previewName: PreviewStepsModalData["previewName"];
  @Input() theSteps: PreviewStepsModalData["theSteps"]
  @Input() orgId: PreviewStepsModalData["orgId"];
  @ViewChild('previewStepsDialog') previewStepsDialog: TemplateRef<any>;

  constructor(private pageService: PageService,
      private dialogRef: MatDialogRef<PreviewStepsDialogComponent, PreviewStepsModalData>,
      @Inject(MAT_DIALOG_DATA) public data: PreviewStepsModalData
    ) {
      dialogRef.disableClose ??= true;
      this.previewName = data.previewName;
      this.theSteps = data.theSteps;
      this.orgId = data.orgId;
      if (this.data.onClose) this.dialogRef.afterClosed().subscribe(() => { this.data.onClose({ dialog: this.dialog }); });
    }

  previewForm(id: number) {
    this.pageService.previewForm(id, this.orgId);
  }

  previewImage(id: number) {
    this.pageService.previewImage(id, this.orgId);
  }

  previewVideo(id: number) {
    this.pageService.previewVideo(id, this.orgId);
  }

  buttonClick(evt) {
    let ids = evt.data.split('_');
    if(ids[0] == "image")
      this.previewImage(ids[1]);
    else if(ids[0] == "form")
      this.previewForm(ids[1]);
    if(ids[0] == "video")
      this.previewVideo(ids[1]);
  }


  preview(asset: any) {
    //lib.type + '_' + lib.id
    if(asset.type == "image")
      this.previewImage(asset.iid);
    else if(asset.type == "form")
      this.previewForm(asset.iid);
    if(asset.type == "video")
      this.previewVideo(asset.iid);
  }

  close() {
    this.dialogRef.close();
  }
}
