import {Component, OnInit, EventEmitter, NgZone, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ApiService} from '@app/services/api.service';
import {AuthService} from '@app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {
  username: string;
  password: string;
  loginerror: string;
  @ViewChild('login') loginForm;
  config = {
    class: 'login-modal modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: false,
  };

  constructor(
    private modalService: ModalService,
    private apiService: ApiService,
    private auth: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private ar: ActivatedRoute
  ) {}

  ngAfterViewInit() {
    this.loginForm.event.subscribe(res => {
      if (res.type === 'dologin'){
        this.loginForm.loginerror = '';
        this.username = res.username;
        this.password = res.password;

        this.apiService.doLogin(this.username, this.password).subscribe({
            next: (data: { success: boolean; message: string; }) => {
              if (!data.success) return this.modalService.showMessageDialog({ data: { prompt: data.message } });

              this.auth.setSession(data);
              this.ngZone.run(() => {
                this.router.navigate(['profile', 'dashboard']);
              });
            },
            error: (error) => {
              this.loginForm.loginerror = error.message;
            }}
          );
      } else if(res.type == 'forgot') {
        this.apiService.requestPasswordReset(res.username).subscribe(() => {
          this.loginForm.loginerror = "If your email or phone number was found, a reset email or text will be sent. " +
            "Follow the instructions to reset your password."
        });
      }
    });
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'login-form-component',
  styleUrls: ['./login-form.scss'],
  template: `
    <form [formGroup]="loginform" id="login_form" (keyup.enter)="doLogin(loginform)">

        <mat-form-field appearance="outline" class="pal-outline" style="width: 100%; line-height: 1.2em">
            <mat-icon matPrefix> mail_outline</mat-icon>
            <mat-label>Email/Phone Number</mat-label>
            <input matInput style="margin-left: 8px;line-height: 1.2em; height: 1.2em;"#firstFocus type="text" formControlName="username" >
        </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%; line-height: 1.2em">
        <mat-icon matPrefix>lock</mat-icon>
        <mat-label>Password</mat-label>
        <input matInput style="margin-left: 8px;"  type="password" formControlName="password" >
      </mat-form-field>


      <div id="forgot_password" style="margin-top: 1e m; cursor: default" (click)="forgot(loginform)" title="Enter your email or phone number, and click this to get a password reset email or text." >Forgot Password</div>
      <div class="input-group error" id="login_error" style="margin-top: 15px; margin-bottom: 15px;">{{loginerror}}</div>

        <button mat-raised-button style="width: 15em; margin-top: 1.5em; padding: 0.25em;" type="button" (click)="doLogin(loginform)" color="accent" id="login_form_submit">Log In</button>
    </form>`
})

export class LoginModalComponent implements OnInit {

  loginform;
  loginerror: '';
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private formBuilder: UntypedFormBuilder) {}
  @ViewChild('firstFocus') firstFocus: ElementRef;

  ngOnInit() {
    this.loginform = this.formBuilder.group({
      username: '',
      password: ''
    });
    this.loginerror = '';
    setTimeout(() => { this.firstFocus.nativeElement.focus() }, 0);
  }

  forgot(loginform): void {
    if(!loginform.value?.username) {
      return;
    }
    this.event.emit({type: 'forgot', username: loginform.value.username});
  }
  doLogin(f): void {
    if (f.value) {
      this.event.emit({type: 'dologin', username: f.value.username, password: f.value.password});
    }
  }
}
