import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {ListItemEventService} from '@services/list-item-event.service';
import {FormControl, Validators} from '@angular/forms';
import { ItemInfoModalComponent, ItemInfoModalData } from '@app/shared/component/Dialog';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@app/services/modal.service';
import { InstructionSet, InstructionSetSection, InstructionSetSectionItem } from '@app/core/models/pal-instruction-set';
import { PageService } from '@app/services/page.service';
import { PalOrganization } from '@app/core/models/pal-organization';
import { UserService } from '@app/services/user.service';
import { ApiService } from '@app/services/api.service';
import { nanoid } from 'nanoid';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export type SectionInfoModalData = Pick<ModalData, "onClose"> & {
  updateSection: (section: any) => any;
  section: InstructionSetSection;
  instructionSet: InstructionSet
}

@Component({
  selector: 'app-section-info-dialog-component',
  templateUrl: './section-info-dialog.component.html',
  styleUrls: ['./section-info-dialog.component.scss'],
})

export class SectionInfoModalComponent implements OnInit, OnDestroy {
  private dialogResponse: boolean = undefined;
  constructor(private eventService: ListItemEventService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SectionInfoModalComponent, SectionInfoModalData>,
        @Inject(MAT_DIALOG_DATA) public data: SectionInfoModalData,
    private pageService: PageService,
    private userService: UserService,
    private apiService: ApiService
    ) {
      dialogRef.disableClose ??= true;
      if (this.data.onClose) this.dialogRef.afterClosed().subscribe(() => {
        this.data.onClose({ dialog: this.dialogResponse });
      });
      this.section = _.cloneDeep(this.data.section);
      this.org = this.userService.currentOrgObserver.value;
    }

  @Input() setProviders: any[];

  org: PalOrganization;
  section: InstructionSetSection
  items: Observable<InstructionSetSectionItem[]>;
  subs: Subscription[] = [];
  itemStatus: string = "no-items";
  nameField = new FormControl('', {
    validators: [
      Validators.required,
    ],
  });

  ngOnInit() {
    this.items = of(this.section.InstructionSetSectionItems);
  }

  ngOnDestroy() {
    this.subs.forEach(s => {s.unsubscribe()});
  }

  drop(event: CdkDragDrop<InstructionSetSectionItem[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.section.InstructionSetSectionItems[event.currentIndex].order = event.currentIndex + 1;
  }
 
  buttonEvent(events) {
    if (events.event === 'edit') {
      let item = this.section.InstructionSetSectionItems.find(x => x.id === events.data);
      const data = {
        data: {
          updateItem: (item: InstructionSet['InstructionSetSections'][number]['InstructionSetSectionItems'][number]) => {
            this.section.InstructionSetSectionItems[this.section.InstructionSetSectionItems.findIndex(x => x.id === events.data)] = item;
          },
          item: item,
          instructionSet: this.data.instructionSet
        }
      };

      if (typeof item.id === "string" && !item.asset_id) {
        return this.dialog.open<ItemInfoModalComponent, ItemInfoModalData>(ItemInfoModalComponent, data);
      }
      this.apiService.getVideoPreview(item.asset_id, this.org.id).subscribe(video => {
       data['data']['asset'] = video;
        this.dialog.open<ItemInfoModalComponent, ItemInfoModalData>(ItemInfoModalComponent, data);
      });
    } else if (events.event === 'delete') {
      this.removeItem(events.data);
    } else if (events.event === 'play') {
      let i = this.section.InstructionSetSectionItems.find(x => x.id === events.data);
      this.pageService.previewVideo(i.asset_id, this.org.id);
    }
  }

  updateSection() {
    this.dialogResponse = true;
    this.itemStatus = "no-items";
    let valid = true;

    if(this.section.name == "") {
      this.nameField.markAsTouched();
      this.nameField.markAsDirty();
      valid = false;
    }
    if(this.section.InstructionSetSectionItems.length == 0)
    {
      this.itemStatus = "no-items items-error";
      valid = false;
    }

    if(!valid) {
      this.pageService.showErrorMessage("You need to have at least one valid item to save the section.", "OK", 5000);
      return this.dialogResponse = undefined;
    }

    let itemIdx = 1;
    for (const item of this.section.InstructionSetSectionItems) {
      if (item.name.trim() === "" || item.name === "(No item name set, click edit to change)" || !item.asset_id) {
        this.pageService.showErrorMessage(`Section item #${itemIdx} ("${item.name}") is not valid, click on the item [Edit] action to fix it`, "OK", 8000);
        return this.dialogResponse = undefined;
      }
      itemIdx++;
    }
    
    for (let i = 0; i < this.section.InstructionSetSectionItems.length; i++) {
      const currentItem = this.section.InstructionSetSectionItems[i];
      if (currentItem.order <= this.section.InstructionSetSectionItems[i - 1]?.order) {
        currentItem.order = this.section.InstructionSetSectionItems[i - 1].order + 1;
      }
    }
    
    this.data.updateSection(this.section);
    this.dialogRef.close();
  }

  addItem() {
    this.section.InstructionSetSectionItems.push({ id: nanoid(), name: "(No item name set, click edit to change)", order: this.section.InstructionSetSectionItems.length + 1 } as any);
    this.items = of(this.section.InstructionSetSectionItems);
  }

  removeItem(id: number) {
    this.section.InstructionSetSectionItems.splice(this.section.InstructionSetSectionItems.findIndex(x => x.id == id), 1);
    this.items = of(this.section.InstructionSetSectionItems);
  }

  hidePopup() {
    this.dialogResponse = false;
    this.dialogRef.close();
  }
}
