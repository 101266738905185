<h5 mat-dialog-title>Instruction Set Builder - Edit Item Properties</h5>
<div mat-dialog-content>
  <mat-form-field style="display: flex; flex: 1 1 auto">
    <mat-label>Name:</mat-label>
    <input matInput [(ngModel)]="item.name" [formControl]="nameField">
    <mat-error *ngIf="nameField.hasError('required')">
      Item name is required.
    </mat-error>
  </mat-form-field>
  <div style="margin-top: 10px;">
    <button mat-raised-button color="primary" (click)="findVideo()" title="Click here to select a video for this item.">Select Video</button>

    <mat-label style="margin-left: 10px">Video:  {{data.asset?.title || '(No video selected)' }} <div *ngIf="(!this.item.asset_id || !this.data.asset)" style="display: inline-block; margin-left: 2em; color: red;">You must select a video.</div></mat-label>


  </div>
  <hr style="border: 1px solid #090a0c" />
  <div>
    <mat-checkbox [(ngModel)]="item.checked_by_default" (change)="checkAllProviders()"><span style="margin-left: 1em">Checked by Default For Everyone</span></mat-checkbox>
  </div>
  <div *ngIf="orgProviders.length > 0">
    <div class="h6" style="margin-top: 1em;">Check by default only for these providers:</div>
    <div style="padding-left: 1em" *ngFor="let p of checked_providers ">
      <mat-checkbox [name]="p.name.replace(' ', '')"  [(ngModel)]="p.checked" [checked]="p.checked" (change)="item.checked_by_default = false;" value="{{p.name}}"><span style="margin-left: 1em">{{p.name}}</span></mat-checkbox>
    </div>
  </div>
</div>
<mat-dialog-actions style="display: flex; flex-direction: row; justify-content: space-between">
  <button mat-raised-button color="default" (click)="hidePopup()"><mat-icon>cancel</mat-icon> Cancel</button>
  <button mat-raised-button color="accent" (click)="updateItem()"><mat-icon>update</mat-icon> Update</button>
</mat-dialog-actions>
