import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'formly-wrapper-drag-list',
  template: `

    <ng-container #fieldComponent >
    </ng-container>

    `,
    standalone: true,
    imports: [CommonModule, DragDropModule],
})
export class FormlyWrapperDragList extends FieldWrapper {
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.field.fieldGroup, event.previousIndex, event.currentIndex);
    }
}
