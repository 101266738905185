<div class="modal-header">
  <h5 class="modal-title">Find Organization</h5>
</div>
<div class="modal-body">
  <div class="search-field">
    <mat-form-field style="width: 100%;">
      <mat-label>Search for Organization</mat-label>
      <input matInput style="width: 100%;" (input)="searchOrganizations($event.target.value)" type="text"
             id="search_field"
             title="Enter the name of the organization you are trying to find."
             placeholder="search for organizations..." autofocus>
    </mat-form-field>
  </div>
  <div class="results">Results</div>
  <div class="organization-list">
    <div *ngFor="let organization of organizations | async">
      <div (click)="markOrganization(organization)" [class]="organization == selectedOrganization ? 'selected entry' : 'entry'">
        {{organization.name}}
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" (click)="hideModal()">
    <mat-icon>cancel</mat-icon> Cancel
  </button>
  <button mat-raised-button color="accent" (click)="selectOrganization()">
    <mat-icon>check</mat-icon> Select
  </button>
</div>
