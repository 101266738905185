import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent, LoginModalComponent} from './pages/login/login.component';
import {LandingRoutingModule} from './landing.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SingleTaskComponent } from './pages/single-task/single-task.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {MainMaterialModule} from '@core/core/main-material.module';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { RequestPasswordResetComponent } from './pages/request-password-reset/request-password-reset.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { OnboardComponent } from './pages/onboard/onboard.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import {SharedModule} from '@shared/shared.module';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {VgStreamingModule} from '@videogular/ngx-videogular/streaming';
import {NgxMaskModule} from 'ngx-mask';

@NgModule({
  declarations: [LoginComponent, LoginModalComponent, SingleTaskComponent, RequestPasswordResetComponent, NewPasswordComponent, OnboardComponent, PrivacyComponent],
    imports: [
        CommonModule, LandingRoutingModule, FormsModule, ReactiveFormsModule, VgCoreModule, VgOverlayPlayModule, VgControlsModule,
        MainMaterialModule, VgBufferingModule, SharedModule, NgCircleProgressModule, VgStreamingModule, NgxMaskModule
    ]
})
export class LandingModule { }
