import {Injectable} from '@angular/core';
import {PalTask} from '@core/models/pal-task';
import {ApiService} from '@services/api.service';
import {PalMessage} from '@core/models/pal-message';
import {BehaviorSubject, firstValueFrom, Observable, of, Subject} from 'rxjs';
import {Recipient} from '@modules/profile/pages/messages/new-message/new-message.component';
import {PalUser} from '@core/models/pal-user';
import {map, take} from 'rxjs/operators';
import {PalLibraryItem} from '@core/models/pal-library-item';
import {PalOrganization} from '@core/models/pal-organization';
import {PalTaskCategory} from '@core/models/pal-task-category';
import { ModalService } from './modal.service';
import { Media } from '@app/shared/component/Dialog';
import { InstructionSet } from '@app/core/models/pal-instruction-set';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentOrg: number;
  private currentOrgRecord: PalOrganization;
  private currentModule: string;
  public currentModuleObserver: Subject<string>;
  public currentOrgObserver: BehaviorSubject<PalOrganization>;
  private _unsavedInfo: boolean = false;

  constructor(private api: ApiService, private modalService: ModalService) {
    this.currentOrg = -1;
    this.currentModule = '';
    this.currentModuleObserver = new Subject<string>();
    this.currentOrgObserver = new BehaviorSubject<PalOrganization>(null);

  }

  // for global save dialog.
  unsavedChangeMessage(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.modalService.showQuestionDialog({
        data: {
          prompt: "You have unsaved changes, are you sure you want to move away from this page?",
          yesButtonText: "Yes, discard changes.",
          noButtonText: "No, stay here.",
          onResponse: ({ dialog }) => {
            if (dialog)
              this.unsavedInfo = false;
            resolve(dialog);
          },
        }
      })
    });
  }

  get unsavedInfo() {
    return this._unsavedInfo;
  }

  set unsavedInfo(val: boolean) {
    this._unsavedInfo = val;
  }

  private getData(): any {
    //let t = localStorage.getItem('token');
    //let d = jwtDecode(t);
    //return t;
  }

  public async getCurrentUserId(): Promise<number> {
    const user = await firstValueFrom(
      this.getUser().pipe(
        take(1),
        map((user: any) => {
          const palUser: PalUser = user.user;
          return palUser.id;
        })
      )
    );

    return user;
  }


  public getCurrentOrg(): number {

    return this.currentOrg;
  }

  public setCurrentOrg(org: number | PalOrganization) {
    if (typeof org !== "number") {
      this.currentOrgRecord = org;
      this.currentOrgObserver.next(org);
      return;
    }
    if (org) {
      this.currentOrg = org;
      let sub = this.api.getOrganizationRecord(this.currentOrg).subscribe(o => {
        this.currentOrgRecord = o;
        this.currentOrgObserver.next(o);
        sub.unsubscribe();
      });
    } else {
      this.currentOrg = -1;
      this.currentOrgRecord = null;
      this.currentOrgObserver.next(null);
    }
  }

  public async getCurrentOrgRecord(org_id = null): Promise<PalOrganization> {
    return new Promise((resolve, reject) => {
      if (this.currentOrgRecord && (org_id == null || this.currentOrgRecord.id == org_id)) {
        return resolve(this.currentOrgRecord);
      }

      this.currentOrg = org_id ? org_id : this.currentOrg;

      this.api.getOrganizationRecord(this.currentOrg).subscribe(o => {
        // ! FIXME: This function shouldn't exist or it should be used everywhere, o.org doesn't exist.
        // @ts-ignore
        this.currentOrgRecord = o.org;
        resolve(this.currentOrgRecord);
      });
    });
  }

  public getPermissions(org_id, user_id = null) : Observable<any> {
    return this.api.getPermissions(org_id, user_id);
  }

  getAvailablePermissionsGroups(){
    const user = this.getData();
    return this.api.getAvailablePermissionsGroups();
  }

  public getOrganization(org_id = null): Observable<any> {
    // This should be used by the resolverFn.
    if (this.currentOrgRecord && (org_id == null || this.currentOrgRecord.id == org_id)) {
      return of(this.currentOrgRecord);
    }


    if (org_id) {
      this.currentOrg = org_id;
    }

    return this.api.getOrganizationRecord(this.currentOrg);
  }

  public updateOrganization(o): Observable<any> {

    return this.api.updateOrganization(o);
  }

  public setOrgModule(mod) {
    this.currentModuleObserver.next(mod);
  }

  public getUser(): Observable<PalUser> {
    return this.api.getUserRecord();
  }

  public updateUser(data): Promise<string> {
    return new Promise((resolve, reject) => {
      this.api.updateUserRecord(data).subscribe((c) => {
        if (c.success) {
          return resolve('success');
        } else {
          return reject(c.error);
        }
      });
    });
  }

  public updateHighlights(org_id, highlights, record_id): Observable<any> {

    highlights['record_id'] = record_id;
    return this.api.updateHighlights(org_id, highlights, record_id);
  }


  public getProtocol(orgId, protocolId): Observable<any> {

    // {protocol: p, licensed: licensed, days: steps}
    return this.api.getProtocol(orgId, protocolId);
  }

  public saveProtocol(orgId, protocol): Observable<any> {

    return this.api.saveProtocol(orgId, protocol);
  }

  public deleteProtocol(orgId, protocolId): Observable<any> {

    return this.api.deleteProtocol(orgId, protocolId);
  }

  public deleteAssignedProtocol(orgId, userId, assignedId): Observable<any> {

    return this.api.deleteAssignedProtocol(orgId, userId, assignedId);
  }

  public getTasks(profile_id = null): Observable<PalTask[]> {
    return this.api.getTaskList(profile_id);
  }

  public getFormAnswers(orgId, userId, trainingPlanId): Observable<any> {

    return this.api.getFormAnswers(orgId, userId, trainingPlanId);
  }

  public getOrganizations(): Observable<any> {

    let x = this.api.getOrganizations();
    if (this.currentOrg == -1) {
      x.subscribe(y => {
        this.currentOrg = y[0].id;
      });
    }
    return x;
  }

  getOrg(currentOrg) {
    let x = this.api.getOrganizations();
  }

  public getMessageCount(profile_id = null): Observable<number> {

    return this.api.getMessageCount(profile_id);
  }

  public getMessages(profile_id = null): Observable<PalMessage[]> {
    return this.api.getMessages(profile_id);
  }

  public getProfiles(): Observable<any[]> {
    return this.api.getProfiles();
  }

  public getOrgOverviewReport(org_id, start_date, end_date): Observable<any[]> {

    return this.api.getOrgOverviewReport(org_id, start_date, end_date);
  }

  public getProfile(id): Observable<any> {

    return this.api.getProfile(id);
  }

  public updateProfile(id, data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.updateProfile(id, data).subscribe((c) => {
        if (c.success) {
          return resolve('success');
        } else {
          return reject(c.error);
        }
      });
    });
  }

  public deleteProfile(id): Promise<string> {
    return new Promise((resolve, reject) => {
      this.api.deleteProfile(id).subscribe((c) => {
        if (c.success) {
          return resolve('');
        } else {
          return reject(c.error);
        }
      });
    });
  }

  public deleteMessage(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.deleteMessage(id).subscribe((c: string) => {
        if (c == 'success') {
          return resolve('');
        } else {
          return reject(c);
        }
      });
    });
  }

  public getMessageRecipients(profile_id = null): Observable<Recipient[]> {

    return this.api.getMessageRecipients(profile_id);
  }

  public sendMessage(message): Observable<any> {
    //message.from_user = user.id;
    return this.api.sendMessage(message);
  }

  public getTaskSteps(task_id): Observable<any> {

    return this.api.getTaskSteps(task_id);
  }

  public markStepStarted(task_id, step_id, total_time): Observable<any> {

    return this.api.markStepStarted(task_id, step_id, total_time);
  }

  public updateStepProgress(task_id, step_id, time): Observable<any> {

    return this.api.updateStepProgress(task_id, step_id, time);
  }

  public markStepCompleted(task_id, step_id): Observable<any> {

    return this.api.markStepCompleted(task_id, step_id);
  }

  public starStep(task_id, step_id, num_stars): Observable<any> {

    return this.api.starStep(task_id, step_id, num_stars);
  }

  public starTask(task_id, num_stars): Observable<any> {

    return this.api.starTask(task_id, num_stars);
  }

  public taskFeedback(task_id, feedback): Observable<any> {

    return this.api.taskFeedback(task_id, feedback);

  }

  public getOrganizationDashboard(org_id): Observable<any> {

    return this.api.getOrgDashboard(org_id);
  }

  public getOrganizationLibrary(org_id): Observable<any[]> {

    return this.api.getOrgLibrary(org_id);
  }

  public getLibraryTags(org_id): Observable<any[]> {

    return this.api.getOrgLibraryTags(org_id, '');
  }

  public searchForLibraryItems(org_id: number, query: string, tags: string[], searchOnly: string = null): Observable<PalLibraryItem[]> {
    if (query === '~~') {
      return of([]);
    }

    return this.api.searchOrgLibrary(org_id, query, tags, searchOnly);
  }

  public searchForAssignableItems(org_id: number, query: string): Observable<PalLibraryItem[]> {

    return this.api.searchForAssignableItems(org_id, query);
  }

  public getLibraryItemForAssign(org_id: number, type: string, id: number): Observable<any> {

    return this.api.getLibraryItemForAssign(org_id, type, id);
  }

  public searchForMembers(org_id: number, query: string, show_inactive: boolean = false, sort_options: string = null, is_trainer: boolean = false): Observable<any[]> {
    if (query == '-1' || typeof query !== 'string') {
      return of([]);
    }

    return this.api.searchOrgMembers(org_id, query, show_inactive, sort_options, is_trainer);
  }

  public getMemberRecord(org_id: number, id): Observable<any> {

    return this.api.getMemberRecord(org_id, id);
  }

  public searchTargetsForAssign(org_id: number, query: string): Observable<any[]> {

    return this.api.searchTargetsForAssign(org_id, query);
  }

  public searchForTemplates(org_id: number, query: string, tags: string[]): Observable<any[]> {

    return this.api.searchForTemplates(org_id, query, tags);
  }

  public getProtocolsForAssign(org_id: number): Observable<any> {

    return this.api.getProtocolsForAssign(org_id);
  }

  public getTemplate(org_id: number, template_id: number): Observable<any> {

    return this.api.getTemplate(org_id, template_id);
  }

  public getTemplateStepsList(org_id: number, template_id: number): Observable<any[]> {

    return this.api.getTemplateStepsList(org_id, template_id);
  }

  public getTemplateTags(org_id): Observable<any[]> {

    return this.api.getOrgTemplateTags(org_id);
  }

  public saveTemplate(org_id, template): Observable<any> {

    return this.api.saveTemplate(org_id, template);
  }

  public deleteTemplate(org_id, template): Observable<any> {

    return this.api.deleteTemplate(org_id, template);
  }

  public getForm(org_id, form_id): Observable<any> {

    return this.api.getForm(org_id, form_id);
  }

  public saveForm(org_id, form, name, description, tags, category, color, form_id = undefined): Observable<any> {

    return this.api.saveForm(org_id, form, name, description, tags, category, color, form_id);
  }

  public deleteForm(org_id, form_id): Observable<any> {

    return this.api.deleteForm(org_id, form_id);
  }


  public getVideoPreview(id): Observable<Media> {
    let org = this.currentOrgObserver.value;
    return this.api.getVideoPreview(id, org.id);
  }

  public searchForProtocols(org_id: number, query: string, tags: string[]): Observable<any[]> {

    return this.api.searchForProtocols(org_id, query, tags);
  }

  public getProtocolTags(org_id): Observable<any[]> {

    return this.api.getProtocolTags(org_id);
  }

  public getClientTask(org_id, client_id, task_id): Observable<any> {

    return this.api.getClientTask(org_id, client_id, task_id);
  }

  public saveTask(org_id, client_id, task_id, task, onlySecondaries = false): Observable<any> {

    return this.api.saveClientTask(org_id, client_id, task_id, task, onlySecondaries);
  }

  public resendTask(org_id, client_id, task_id): Observable<any> {

    return this.api.resendTask(org_id, client_id, task_id);
  }

  public multiAssignTask(org_id, targets, task): Observable<any> {

    return this.api.multiAssignTask(org_id, targets, task);
  }

  public assignInstructionSet(org_id, client_id, data): Observable<any> {

    return this.api.assignInstructionSet(org_id, client_id, data.provider, data.items, data.dueDate, data.expires, data.name, data.secondary_contacts, data.category_id);
  }

  public assignProtocol(org_id, client_id, protocol_id, days, provider, secondary_contacts, start_date): Observable<any> {

    return this.api.assignProtocol(org_id, client_id, protocol_id, days, provider, secondary_contacts, start_date);

  }

  public searchForGroups(org_id, search): Observable<any[]> {

    return this.api.searchForGroups(org_id, search);
  }

  public getGroup(org_id, id): Observable<any> {

    return this.api.getGroup(org_id, id);
  }

  public saveGroup(org_id, id, group): Observable<any> {

    return this.api.saveGroup(org_id, id, group);
  }

  public deleteGroup(org_id, id): Observable<string> {

    return this.api.deleteGroup(org_id, id);
  }

  public getVideo(org_id, id): Observable<any> {

    return this.api.getVideo(org_id, id);
  }

  public saveVideo(org_id, id, v, fileToUpload, newThumbnail, thumbnailTime): Observable<any> {

    return this.api.saveVideo(org_id, id, v, fileToUpload, newThumbnail, thumbnailTime);
  }

  public deleteVideo(org_id, id): Observable<string> {

    return this.api.deleteVideo(org_id, id);
  }

  public uploadVideo(org_id, video_id, file: File, cdn_name: string): Observable<any> {

    return this.api.uploadVideo(org_id, video_id, file, cdn_name);
  }

  public uploadThumbnail(org_id, video_id, thumb, cdn_name: string): Observable<any> {

    return this.api.uploadThumbnail(org_id, video_id, thumb, cdn_name);
  }

  public getImage(org_id, id): Observable<any> {

    return this.api.getImage(org_id, id);
  }

  public saveImage(org_id, id, i): Observable<any> {

    return this.api.saveImage(org_id, id, i);
  }

  public deleteImage(org_id, id): Observable<any> {

    return this.api.deleteImage(org_id, id);
  }

  public inviteUser(org_id, new_user, task = null, instruction_set = null, secondary_contacts = null) : Observable<any> {
    return this.api.inviteUser(org_id, new_user, task, instruction_set, secondary_contacts);
  }

  public getMemberInfo(org_id, member_id): Observable<any> {

    return this.api.getMemberInfo(org_id, member_id);
    /* return format:
        this.user = data.user;
        this.taskList = data.taskList;
        this.highlights = data.highlights;
        this.notes = data.notes;
        this.groups = data.groups;
        this.messages = data.messages;
        this.profiles = data.profiles;
        this.is_trainer = data.is_trainer;
        this.is_active = data.is_active;
     */
  }

  public resendInvite(org_id, member_id): Observable<string> {

    return this.api.resendInvite(org_id, member_id);
  }

  public toggleTrainer(org_id, member_id): Observable<string> {

    return this.api.toggleTrainer(org_id, member_id);
  }

  public toggleActive(org_id, member_id): Observable<string> {

    return this.api.toggleActive(org_id, member_id);
  }

  public addMemberToGroup(org_id, member_id, group): Observable<string> {

    return this.api.addMemberToGroup(org_id, member_id, group);
  }

  public removeMemberFromGroup(org_id, member_id, group): Observable<string> {

    return this.api.removeMemberFromGroup(org_id, member_id, group);
  }

  public toggleTaskActive(org_id, member_id, task_id): Observable<string> {

    return this.api.toggleTaskActive(org_id, member_id, task_id);
  }

  public deleteTask(org_id, member_id, task_id): Observable<string> {

    return this.api.deleteTask(org_id, member_id, task_id);
  }

  public addNoteToMember(org_id, member_id, note): Observable<any> {

    return this.api.addNoteToMember(org_id, member_id, note);
  }

  public deleteNote(org_id, member_id, note_id): Observable<any> {

    return this.api.deleteNote(org_id, member_id, note_id);
  }

  public getReportOrganizationalOverview(org_id): Observable<any> {

    return this.api.reportOrganizationalOverview(org_id);
  }

  public getReportClientStatus(org_id): Observable<any> {

    return this.api.reportClientStatus(org_id);
  }

  public getReportTaskSummary(org_id): Observable<any> {

    return this.api.reportTaskSummary(org_id);
  }

  public updateProvidersDepartments(org_id, data): Observable<any> {

    return this.api.updateProvidersDepartments(org_id, data);
  }

  public updateInstructionSets(org_id, sets, providers): Observable<any> {

    return this.api.updateInstructionSets(org_id, sets, providers);
  }
  
  public saveInstructionSet(org_id, payload: { InstructionSet: InstructionSet }): Observable<any> {

    return this.api.saveInstructionSet(org_id, payload);
  }
  
  public deleteInstructionSet(org_id, instruction_set_id): Observable<any> {

    return this.api.deleteInstructionSet(org_id, instruction_set_id);
  }

  public getProviders(org_id): Observable<{ id: number; name: string; }[]> {
    return this.api.getProviders(org_id);
  }
  
  public getInstructionSets(org_id: number, options?: object): Observable<{ id: number; name: string; }[]> {
    return this.api.getInstructionSets(org_id, options);
  }
  
  public getInstructionSet(org_id, instruction_set_id): Observable<InstructionSet> {
    return this.api.getInstructionSet(org_id, instruction_set_id);
  }

  getTaskCategories(org_id: number) {

    return this.api.getTaskCategories(org_id);
  }

  saveTaskCategory(org_id: number, data: PalTaskCategory) {

    return this.api.saveTaskCategory(org_id, data);
  }

  deleteTaskCategory(org_id: number, id: number) {

    return this.api.deleteTaskCategory(org_id, id);
  }

  public getTaskNotificationHistory(org_id, task_id): Observable<any> {

    return this.api.getTaskNotificationHistory(org_id, task_id);
  }

  getAvailableUserPermissions(){
    return this.api.getAvailablePermissions();
  }
  async updateUserPermissions(permissionId: string, userId: number = null){
    if(userId == null) {
      userId = await this.getCurrentUserId();
    }
    const orgId = this.currentOrgRecord.id;
    return this.api.updatePermissions(orgId, userId, permissionId);
  }

  public getFormAnswersReport(org_id, start_date, end_date) : Observable<any> {
    let user = this.getData();
    return this.api.getFormAnswersReport(user, org_id, start_date, end_date);
  }
  private dragFlag : boolean = false;
  public startDrag() {
    this.dragFlag = true;
  }
  public isDragging() : boolean { return this.dragFlag }
  public stopDrag() {
    this.dragFlag = false;
  }
}
